export enum AttachmentIcons {
  PDF = 'https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_3_pdf_x32.png',
  WORD = 'https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_word_x32.png',
  EXCEL = 'https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_excel_x32.png',
  POWERPOINT = 'https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_powerpoint_x32.png',
  TEXT = 'https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_text_x32.png',
  IMAGE = 'https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_image_x32.png',
  OTHER = 'https://drive-thirdparty.googleusercontent.com/32/type/application/octet-stream',
}

export const AttachmentTypes = [
  {
    name: 'GOOGLE_DOCS',
    icon: AttachmentIcons.WORD,
    mimeTypes: ['application/vnd.google-apps.document'],
    exportMimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    workspaceDocument: true
  },
  {
    name: 'GOOGLE_SHEETS',
    icon: AttachmentIcons.EXCEL,
    mimeTypes: ['application/vnd.google-apps.spreadsheet'],
    exportMimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    workspaceDocument: true
  },
  {
    name: 'GOOGLE_PRESENTATIONS',
    icon: AttachmentIcons.POWERPOINT,
    mimeTypes: ['application/vnd.google-apps.presentation'],
    exportMimeType: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    workspaceDocument: true
  },
  {
    name: 'GOOGLE_DRAWINGS',
    icon: AttachmentIcons.PDF,
    mimeTypes: ['application/vnd.google-apps.drawing'],
    exportMimeType: 'application/pdf',
    workspaceDocument: true
  },
  {
    name: 'PDF',
    ext: ['.pdf'],
    icon: AttachmentIcons.PDF,
    mimeTypes: ['application/pdf'],
    exportMimeType: null,
    workspaceDocument: false
  },
  {
    name: 'WORD',
    ext: ['.doc', '.docm', '.docx', '.dot', '.dotm', 'dotx', '.odt', '.rtf'],
    icon: AttachmentIcons.WORD,
    mimeTypes: ['application/msword',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
      'application/vnd.ms-word.document.macroEnabled.12',
      'application/vnd.ms-word.template.macroEnabled.12',
      'application/vnd.oasis.opendocument.text'],
    exportMimeType: null,
    workspaceDocument: false
  },
  {
    name: 'EXCEL',
    ext: ['.csv', 'ods', '.xlam', '.xls', '.xlsb', '.xlsm', '.xlsx', '.xlt', '.xltm', '.xltx', '.xlw'],
    icon: AttachmentIcons.EXCEL,
    mimeTypes: [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
      'application/vnd.ms-excel.sheet.macroEnabled.12',
      'application/vnd.ms-excel.template.macroEnabled.12',
      'application/vnd.ms-excel.addin.macroEnabled.12',
      'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
      'application/vnd.oasis.opendocument.spreadsheet',
      'text/csv'
    ],
    exportMimeType: null,
    workspaceDocument: false
  },
  {
    name: 'POWERPOINT',
    ext: ['.odp', '.pot', '.potm', '.potx', '.ppa', '.ppam', '.pps', '.ppsm', '.ppsx', '.ppt', '.pptm', '.pptx'],
    icon: AttachmentIcons.POWERPOINT,
    mimeTypes: [
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.openxmlformats-officedocument.presentationml.template',
      'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
      'application/vnd.ms-powerpoint.addin.macroEnabled.12',
      'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
      'application/vnd.ms-powerpoint.template.macroEnabled.12',
      'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
      'application/vnd.oasis.opendocument.presentation'
    ],
    exportMimeType: null,
    workspaceDocument: false
  },
  {
    name: 'TEXT',
    ext: ['.txt', '.json'],
    icon: AttachmentIcons.TEXT,
    mimeTypes: ['text/plain', 'application/json'],
    exportMimeType: null,
    workspaceDocument: false
  },
  {
    name: 'IMAGE',
    ext: ['.jpeg', '.png', '.gif', '.jpg'],
    icon: AttachmentIcons.IMAGE,
    mimeTypes: ['image/jpeg', 'image/png', 'image/gif'],
    exportMimeType: null,
    workspaceDocument: false
  },
  {
    name: 'OTHER',
    ext: [],
    icon: AttachmentIcons.OTHER,
    mimeTypes: [],
    exportMimeType: null,
    workspaceDocument: false
  }
];
