import {AfterViewInit, Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {FormControl, FormGroup} from '@angular/forms';
import {AuditLogsViewerComponent} from '../viewer/audit-logs-viewer.component';
import {AuditLogService} from '../../../../services/audit-log.service';
import {UserService} from '../../../../services/user.service';
import {DialogService} from '../../../../services/dialog.service';
import {AuditLog, AuditLogFilterValues} from '../../../../models/audit-log.model';
import {AuditLogDataSource} from '../../../../models/audit-log-data-source';
import {tap} from 'rxjs/operators';
import {DEFAULT_AUDIT_LOGS_ITEMS_PAGE_NUMBER} from '../../../../constants/audit-log-constants';

@Component({
  selector: 'app-audit-logs-list',
  templateUrl: './audit-logs-list.component.html',
  styleUrls: ['./audit-logs-list.component.scss']
})
export class AuditLogsListComponent implements OnInit, AfterViewInit, OnDestroy {

  public dataSource: AuditLogDataSource;
  public auditLogsCount: number;
  private auditLogFilterValues: AuditLogFilterValues = new AuditLogFilterValues();

  public eventTypeFilterList: string[];
  public authorEmailFilterList: string[];
  public libraryIdFilterList: string[];
  public classIdFilterList: string[];
  public documentIdFilterList: string[];
  public logLevelFilterList = ['INFO', 'WARNING', 'SEVERE'];
  public prevPageSize: number = DEFAULT_AUDIT_LOGS_ITEMS_PAGE_NUMBER;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public columns = [
    {name: 'eventType', show: true},
    {name: 'eventDate', show: true},
    {name: 'logLevel', show: true},
    {name: 'library', show: true},
    {name: 'documentClass', show: true},
    {name: 'document', show: true},
    {name: 'author', show: true},
    {name: 'view', show: true}];

  public auditLogs: AuditLog[];
  public dateRangeFormGroup: FormGroup;

  typeFilter = new FormControl();
  libraryFilter = new FormControl();
  classFilter = new FormControl();
  authorFilter = new FormControl();
  documentFilter = new FormControl();
  logLevelFilter = new FormControl();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private auditLogService: AuditLogService,
              private ngZone: NgZone,
              private dialog: MatDialog,
              private userService: UserService,
              private dialogService: DialogService) {

    this.dataSource = new AuditLogDataSource(this.auditLogService, this.ngZone, this.dialogService);
  }

  ngOnInit(): void {
    this.initDateRangeFormGroup();
    this.countAuditLogs(this.auditLogFilterValues);
    this.initFilters();
    this.dataSource.loadAuditLogPage(0, this.auditLogFilterValues);
  }

  initDateRangeFormGroup(): void {
    this.dateRangeFormGroup = new FormGroup({
      start: new FormControl(),
      end: new FormControl()
    });
  }

  ngAfterViewInit(): void {
    this.initPaginator();
  }

  ngOnDestroy(): void {
    this.loadingSubject.unsubscribe();
  }

  view(auditLog: AuditLog): void {
    this.dialog.open(AuditLogsViewerComponent, {
      data: {auditLog},
      width: '630px'
    });
  }

  protected countAuditLogs(filters: AuditLogFilterValues): void {
    this.auditLogService.count(filters).subscribe({
      next: (resp) => this.auditLogsCount = resp.auditLogCount,
      error: (error) => this.dialogService.error(error?.error?.error?.message ?? error.message)
    });
  }

  refresh(): void {
    this.resetAuditLogFilterValues();
    this.initTable();
  }

  displayedColumns(): string[] {
    return this.columns.filter(column => column.show).map(column => column.name)
      .filter((value, index, self) => self.indexOf(value) === index && !!value);
  }

  get auditTypes(): string[] {
    if (this.eventTypeFilterList) {
      return this.eventTypeFilterList;
    }
  }

  get auditAuthors(): string[] {
    if (this.authorEmailFilterList) {
      return this.authorEmailFilterList;
    }
  }

  get auditLibraries(): string[] {
    if (this.libraryIdFilterList) {
      return this.libraryIdFilterList;
    }
  }

  get auditClasses(): string[] {
    if (this.classIdFilterList) {
      return this.classIdFilterList;
    }
  }

  get auditDocuments(): string[] {
    if (this.documentIdFilterList) {
      return this.documentIdFilterList;
    }
  }

  initFilters(): void {
    this.auditLogService.getFieldsTypes('eventType').subscribe({
      next: (resp) => this.eventTypeFilterList = resp.auditLogFilterTypeValues,
      error: (error) => this.dialogService.error(error?.error?.error?.message ?? error.message)
    });
    this.auditLogService.getFieldsTypes('authorEmail').subscribe({
      next: (resp) => this.authorEmailFilterList = resp.auditLogFilterTypeValues,
      error: (error) => this.dialogService.error(error?.error?.error?.message ?? error.message)
    });
    this.auditLogService.getFieldsTypes('libraryId').subscribe({
      next: (resp) => this.libraryIdFilterList = resp.auditLogFilterTypeValues,
      error: (error) => this.dialogService.error(error?.error?.error?.message ?? error.message)
    });
    this.auditLogService.getFieldsTypes('classId').subscribe({
      next: (resp) => this.classIdFilterList = resp.auditLogFilterTypeValues,
      error: (error) => this.dialogService.error(error?.error?.error?.message ?? error.message)
    });
    this.auditLogService.getFieldsTypes('documentId').subscribe({
      next: (resp) => this.documentIdFilterList = resp.auditLogFilterTypeValues,
      error: (error) => this.dialogService.error(error?.error?.error?.message ?? error.message)
    });
  }

  protected initTable(): void {
    this.paginator.pageIndex = 0;
    this.countAuditLogs(this.auditLogFilterValues);
    this.dataSource.init(this.paginator.pageSize, 0, this.auditLogFilterValues);
  }

  initPaginator(): void {
    this.paginator.page
      .pipe(
        tap((event: PageEvent) => {
          if (this.prevPageSize != event.pageSize) {
            // items pre page are change -> table is initialized again
            this.initTable();
            this.prevPageSize = event.pageSize;
          } else {
            this.dataSource.loadAuditLogPage(event.pageIndex, this.auditLogFilterValues, this.paginator.pageIndex, this.paginator.pageSize);
          }
        })
      ).subscribe();
  }

  public getPageSize(): number {
    return DEFAULT_AUDIT_LOGS_ITEMS_PAGE_NUMBER;
  }

  search(): void {
    this.auditLogFilterValues.eventType = this.typeFilter.value;
    this.auditLogFilterValues.libraryId = this.libraryFilter.value;
    this.auditLogFilterValues.classId = this.classFilter.value;
    this.auditLogFilterValues.author = this.authorFilter.value;
    this.auditLogFilterValues.documentId = this.documentFilter.value;
    this.auditLogFilterValues.logLevel = this.logLevelFilter.value;
    this.auditLogFilterValues.startDate = this.dateRangeFormGroup.value.start ? this.dateRangeFormGroup.value.start.getTime() : undefined;
    this.auditLogFilterValues.endDate = this.dateRangeFormGroup.value.end ? this.dateRangeFormGroup.value.end.getTime() : undefined;

    this.initTable();
  }

  private resetAuditLogFilterValues(): void {
    this.auditLogFilterValues.logLevel = undefined;
    this.auditLogFilterValues.documentId = undefined;
    this.auditLogFilterValues.eventType = undefined;
    this.auditLogFilterValues.author = undefined;
    this.auditLogFilterValues.libraryId = undefined;
    this.auditLogFilterValues.classId = undefined;
    this.auditLogFilterValues.startDate = undefined;
    this.auditLogFilterValues.endDate = undefined;

    this.typeFilter.reset('');
    this.libraryFilter.reset('');
    this.classFilter.reset('');
    this.authorFilter.reset('');
    this.documentFilter.reset('');
    this.logLevelFilter.reset('');
    this.dateRangeFormGroup.reset();
  }

}
