import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {ApiVersion} from '../constants/apis';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {OssDownloadResult} from '../models/oss-download-result.model';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ObjectStorageService {

  protected baseUrl = environment.ossApi;

  constructor(private http: HttpClient,
              private authService: AuthService) {
  }

  public getDownloadLink(domain: string,
                         library: string,
                         classId: string,
                         document: string,
                         object: string): Observable<OssDownloadResult> {
    const url = this.baseUrl + '/' + ApiVersion.V1 + '/' + domain + '/' + library + '/' + classId + '/' + document + '/' + object + '/download/';
    const accessToken = this.authService.getToken();
    const header = new HttpHeaders({Authorization: `Bearer ${accessToken}`});
    return this.http.get<OssDownloadResult>(url, {headers: header});
  }
}
