export enum Apis {
  AUTH2 = 'auth2',
  CLIENT = 'client',
  DRIVE = 'drive'
}

export enum ApiVersion{
  V1 = 'v1',
  V3 = 'v3'
}

