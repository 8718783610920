<button mat-icon-button class="close-button" [mat-dialog-close]="false">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h2 mat-dialog-title class="header">{{'menu.schedule' | translate}}:  {{auditLog.author}}</h2>
<div mat-dialog-content>
  <div fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="column-container margin-bottom-container">
      <div fxLayout="column" fxFlex="48">
        <mat-label class="grey-label">uuid</mat-label>
        <p>{{auditLog.uuid}}</p>
      </div>
      <div fxLayout="column" fxFlex="48">
        <mat-label
          class="grey-label">Event Type</mat-label>
        <p class="event-type" [matTooltip]="auditLog.eventType">{{auditLog.eventType}}</p>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="column" fxFlex="48">
        <mat-label class="grey-label">Author</mat-label>
        <p>{{auditLog.author}}</p>
      </div>
      <div fxLayout="column" fxFlex="48">
        <mat-label
          class="grey-label">Error Code</mat-label>
        <p>{{auditLog.errorCode}}</p>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="column" fxFlex="100">
        <mat-label class="grey-label">Message</mat-label>
        <p>{{auditLog.message}}</p>
      </div>
    </div>

    <div class="actions" fxLayout="row" fxLayoutAlign="end center">
      <button mat-button mat-dialog-close  [mat-dialog-close]="false">CLOSE</button>
    </div>

  </div>
</div>

