<section fxLayout="row" fxLayoutAlign="start center" class="main" *ngIf="isNotReady && !isWithError">
  <section class="icon-container">
    <mat-icon class="status-icon" svgIcon="lib_not_ready"></mat-icon>
  </section>
  <section fxLayout="column" fxLayoutAlign="start" class="content-container">
    <section fxLayout="row" fxLayoutAlign="start">
      <p class="status-title">{{'libraries.edit.status.not_ready.title'|translate}}</p>
    </section>
    <section fxLayout="row" fxLayoutAlign="start" *ngIf="dataSource">
      <p class="status-description grey-label">
        <span *ngIf="!expanded">
                 {{dataSource.length}}
          <span *ngIf="dataSource.length === 1">{{'libraries.edit.status.class'|translate}}</span>
        <span *ngIf="dataSource.length > 1">{{'libraries.edit.status.classes'|translate}}</span>
          {{'libraries.edit.status.not_ready.description1'|translate}}
        </span>  {{'libraries.edit.status.not_ready.description2'|translate}}
      </p>
    </section>
    <section fxLayout="row" fxLayoutAlign="start" *ngIf="!dataSource">
      <p class="status-description grey-label">
        {{'libraries.edit.status.no_class'|translate}}
      </p>
    </section>
  </section>
</section>
<section fxLayout="row" fxLayoutAlign="start center" class="main" *ngIf="isWithError">
  <section fxLayout="row" fxLayoutAlign="start center" class="sub-main">
  <section class="icon-container">
    <mat-icon class="status-icon" svgIcon="lib_error"></mat-icon>
  </section>
  <section fxLayout="column" fxLayoutAlign="start" class="content-container">
    <section fxLayout="row" fxLayoutAlign="start">
      <p class="status-title">{{'libraries.edit.status.error.title'|translate}}</p>
    </section>
    <section fxLayout="row" fxLayoutAlign="start" *ngIf="dataSource">
      <p class="status-description grey-label">
        {{dataSource.length}}
        <span *ngIf="dataSource.length === 1">{{'libraries.edit.status.class'|translate}}</span>
        <span *ngIf="dataSource.length > 1">{{'libraries.edit.status.classes'|translate}}</span>
        {{'libraries.edit.status.error.description'|translate}}
      </p>
    </section>
    <section fxLayout="row" fxLayoutAlign="start" *ngIf="!dataSource">
      <p class="status-description grey-label">
        {{'libraries.edit.status.no_class'|translate}}
      </p>
    </section>
  </section>
  </section>
</section>
<section fxLayout="column" *ngIf="dataSource">
  <section fxLayout="row" fxLayoutAlign="start center" class="expander">
    <mat-icon class="expand-icon" *ngIf="!expanded">arrow_drop_down</mat-icon>
    <mat-icon class="expand-icon" *ngIf="expanded">arrow_drop_up</mat-icon>
    <p  (click)="toggleExpand()">
      <span *ngIf="expanded"> {{'libraries.edit.status.hide'|translate}}</span>
      <span *ngIf="!expanded"> {{'libraries.edit.status.show'|translate}}</span>
    </p>
  </section>
  <section class="table-container" [@tableExpand]="expanded ? 'expanded' : 'collapsed'">
    <app-document-class-states-table [dataSource]="dataSource"
                                     [libraryId]="libraryId"></app-document-class-states-table>
  </section>
</section>
