import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {ApiVersion} from '../constants/apis';
import {environment} from '../../environments/environment';
import {LibraryApiResponse} from '../models/library-api-response';
import {LibraryDetails} from '../models/library.model';
import {UnbindRetentionsCommand} from '../models/retention.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PolicyType} from '../constants/retention-constants';


@Injectable({
  providedIn: 'root'
})
export class LibraryService extends AbstractService {

  constructor(private http: HttpClient) {
    super();
  }

  countLibraries(filter: string, query: string): Observable<{ libraryCount: number }> {
    const url = this.toUrl('library', 'count', ApiVersion.V1);
    let params = new HttpParams()
      .set('filter', filter);

    if (query) {
      params = params.append('query', query)
    }
    return this.http.get<{ libraryCount: number }>(url, {params});
  }

  getLibrariesByPolicyType(policyType: PolicyType): Observable<{ libraries: string[] }> {
    const url = this.toUrl('library', 'libraries/' + policyType, ApiVersion.V1);
    return this.http.get<{ libraries: string[] }>(url);
  }

  list(filter: string, query: string, nextPageToken: string, items: number): Observable<LibraryApiResponse> {
    const url = this.toUrl('library', 'list', ApiVersion.V1);
    let params = new HttpParams()
      .set('filter', filter)
      .set('items', String(items));

    if (query) {
      params = params.append('query', query)
    }

    if (nextPageToken) {
      params = params.append('nextPageToken', nextPageToken);
    }

    return this.http.get<LibraryApiResponse>(url, {params});
  }

  details(libraryId: string): Observable<LibraryDetails> {
    const url = this.toUrl('library', 'library/' + libraryId + '/details', ApiVersion.V1);
    return this.http.get<LibraryDetails>(url);
  }


  recalculate(libraryId: string): Observable<LibraryDetails> {
    const url = this.toUrl('library', 'library/' + libraryId + '/recalculate', ApiVersion.V1);
    return this.http.post<LibraryDetails>(url, null);
  }

  unbindAll(libraryId: string, policyIds: UnbindRetentionsCommand): Observable<void> {
    const url = this.toUrl('library', 'library/' + libraryId + '/unbind', ApiVersion.V1);
    return this.http.post<void>(url, policyIds);
  }

  getLibrarySettingsUrl(libraryId: string): string {
    return environment.aodocsUrl + 'editor.html?/#Menu_configlibrarySettings/LibraryId_' + libraryId;
  }

  getConfigClassListUrl(libraryId: string): string {
    return environment.aodocsUrl + 'editor.html?/#Menu_configclassList/LibraryId_' + libraryId;
  }

}
