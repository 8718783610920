<section class="main-content">
  <h1>{{'title.lockunlock' | translate}}</h1>
  <mat-card>

    <p class="black-title-label">{{'unlockLockDocuments.unlockDocuments.title' | translate}}</p>
    <p class="grey-label">{{'unlockLockDocuments.unlockDocuments.subtitle' | translate}}</p>

    <section class="lock-management-form">
      <mat-form-field class="full-width" appearance="fill">
        <mat-label>{{'unlockLockDocuments.unlockDocuments.label' | translate}}</mat-label>
        <input type="text" matInput [formControl]="unlockDocumentForm" autocomplete="off">
        <button mat-button *ngIf="unlockDocumentForm.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearValue()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <br>

      <button mat-button class="btn-background-blue fw-500" (click)="unlock()" [disabled]="isDisabled()">
        {{'unlockLockDocuments.unlockDocuments.labelButton' | translate}}
      </button>
    </section>
  </mat-card>

  <mat-card class="unlocked-document-card no-padding" >
    <p class="black-title-label padding-section">{{'unlockLockDocuments.lockDocuments.title' | translate}}</p>

    <section class="filter-section padding-section" fxLayout="row" fxLayoutAlign="space-between center">
      <section fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxFlex="40" fxFlex.lt-md="60"
               class="search-input-container">
        <app-search-input [valueSubject]="searchValueSubject" [clearSubject]="searchClearSubject"></app-search-input>
      </section>

      <button mat-icon-button (click)="refresh()" class="action-icon"
              matTooltip="{{'unlockLockDocuments.lockDocuments.table.tooltips.refresh' | translate}}">
        <mat-icon>refresh</mat-icon>
      </button>
    </section>

    <div class="spinner-container" *ngIf="loading$ | async">
      <mat-spinner></mat-spinner>
    </div>

    <section  [hidden]="loading$ | async">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" >

        <!-- documentId -->
        <ng-container matColumnDef="documentId">
          <mat-header-cell *matHeaderCellDef>{{'unlockLockDocuments.lockDocuments.table.documentId' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.documentId}} </mat-cell>
        </ng-container>

        <!-- documentName -->
        <ng-container matColumnDef="documentName">
          <mat-header-cell *matHeaderCellDef>{{'unlockLockDocuments.lockDocuments.table.documentName' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.documentName}} </mat-cell>
        </ng-container>

        <!-- library -->
        <ng-container matColumnDef="library">
          <mat-header-cell *matHeaderCellDef>{{'unlockLockDocuments.lockDocuments.table.library' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.libraryName}} </mat-cell>
        </ng-container>

        <!-- edit -->
        <ng-container matColumnDef="edit">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <a mat-button [href]="getLibrarySettingsUrl(element)"
               target="_blank"  matTooltip="{{'unlockLockDocuments.lockDocuments.table.tooltips.aodocsDocLink' | translate}}" class="table-button">
              <mat-icon>launch</mat-icon>
            </a>
          </mat-cell>
        </ng-container>

        <!-- lock -->
        <ng-container matColumnDef="lock">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element">

            <button mat-button class="btn-background-blue fw-500" (click)="lock(element)">
              {{'unlockLockDocuments.lockDocuments.labelButton' | translate}}
            </button>

          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns()"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns();"></mat-row>
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">{{"dataNotFound" | translate}}</td>
        </tr>
      </table>
    </section>
    <section [hidden]="loading$ | async">
      <mat-paginator [pageSizeOptions]="[10,20,30]"></mat-paginator>
    </section>
  </mat-card>

</section>
