import {AfterViewInit, Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {LegalHold} from '../../../../models/legal-hold';
import {LegalHoldService} from '../../../../services/legal-hold.service';
import {DialogService} from '../../../../services/dialog.service';
import {LegalHoldCreatorComponent} from '../creator/legal-hold-creator.component';
import {MatDialog} from '@angular/material/dialog';
import {LegalHoldReleaserComponent} from '../releaser/legal-hold-releaser.component';
import {UserService} from '../../../../services/user.service';

@Component({
  selector: 'app-legal-hold-list',
  templateUrl: './legal-hold-list.component.html',
  styleUrls: ['./legal-hold-list.component.scss']
})
export class LegalHoldListComponent implements OnInit, AfterViewInit {

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public dataSource: MatTableDataSource<LegalHold>;
  public columns = [
    {name: 'label', show: true},
    {name: 'created', show: true},
    {name: 'rules', show: true},
    {name: 'documents', show: true},
    {name: 'released', show: true},
    {name: 'edit', show: true}];
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private holdService: LegalHoldService,
              private ngZone: NgZone,
              private userService: UserService,
              private dialog: MatDialog,
              private dialogService: DialogService) {
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    this.list();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  create(): void {
    this.dialog.open(LegalHoldCreatorComponent, {
      height: '530px',
      width: '680px'
    }).afterClosed().subscribe((choice) => {
      if (choice) {
        this.list();
      }
    });
  }

  view(element: LegalHold): void {
    this.dialog.open(LegalHoldReleaserComponent, {
      height: '600px',
      width: '680px',
      data: element
    }).afterClosed().subscribe((choice) => {
      if (choice) {
        this.list();
      }
    });
  }

  list(): void {
    this.loadingSubject.next(true);
    this.holdService.list().subscribe({
      next: resp => {
        this.dataSource.data = resp.items;
        this.loadingSubject.next(false);
      },
      error: error => {
        this.loadingSubject.next(false);
        this.dialogService.error(error?.error?.error?.message ?? error.message);
      }
    });
  }

  refresh(): void {
    this.list();
  }

  convertRules(rulesMap: any): string {
    let rules = '';
    if (rulesMap) {
      rules = this.holdService.convertRules(rulesMap);
    }
    return rules;
  }

  documentCount(legalHold: LegalHold): number {
    return legalHold.documents ? legalHold.documents.length : 0;
  }

  displayedColumns(): string[] {
    return this.columns.filter(column => column.show).map(column => column.name)
      .filter((value, index, self) => self.indexOf(value) === index);
  }

  isAuditor(): boolean {
    return this.userService.isAuditor();
  }
}
