<section class="main-content">
  <h1>{{'title.assignment' | translate}}</h1>
  <mat-card>

    <div class="spinner-container" *ngIf="loading$ | async">
      <mat-spinner></mat-spinner>
    </div>

    <section [hidden]="loading$ | async">
      <p class="grey-label">{{'report.policyAssigment.subtitle' | translate}}</p>

      <form [formGroup]="retentionReportForm">

        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>{{'report.policyAssigment.searchLabel' | translate}}</mat-label>
          <input type="text" matInput [formControl]="retentionSelectorControl" [matAutocomplete]="auto">
          <mat-icon matSuffix>search</mat-icon>

          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="optionSelected()">
            <mat-option *ngFor="let option of filteredOptions | async | slice : 0 : 10"
                        [value]="option">
              {{option.id}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <br>

        <button mat-button class="btn-background-blue fw-500" (click)="launchReport()" [disabled]="exportButtonDisabled">
          {{'report.policyAssigment.exportButtonLabel' | translate}}
        </button>

      </form>
    </section>
  </mat-card>
</section>
