import {Component, Inject, NgZone, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject, Subject} from 'rxjs';
import {AODocsAttachmentEntity, AODocsDocumentEntity} from '../../models/cross-library-search-result.model';
import {DialogService} from '../../services/dialog.service';
import {AttachmentTypes} from '../../constants/attachment-mime-types-constants';
import {ObjectStorageService} from '../../services/object-storage.service';
import {OssDownloadResult} from '../../models/oss-download-result.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-retained-document-attachments-dialog',
  templateUrl: './retained-document-attachments-dialog.component.html',
  styleUrls: ['./retained-document-attachments-dialog.component.scss']
})
export class RetainedDocumentAttachmentsDialogComponent implements OnInit {

  public closeSubject = new Subject<boolean>();
  public document: AODocsDocumentEntity = null;
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              private dialogRef: MatDialogRef<RetainedDocumentAttachmentsDialogComponent>,
              private ngZone: NgZone,
              private objectStorageService: ObjectStorageService,
              private translate: TranslateService,
              private dialogService: DialogService) {
    this.document = data.document;
  }

  ngOnInit(): void {
    this.closeSubject.subscribe((value) => {
      if (value) {
        this.dialogRef.close(true);
      }
    });
  }

  getDownloadLink(attachment: AODocsAttachmentEntity): void {
    this.loadingSubject.next(true);
    if (attachment.gcsLink == null) {
      this.loadingSubject.next(false);
      this.dialogService.error(this.translate.instant('retainedDocuments.attachmentDownloadError'));
    }

    const gcsLinkSplit = attachment.gcsLink.split('/');
    if (gcsLinkSplit.length < 9) {
      this.loadingSubject.next(false);
      this.dialogService.error(this.translate.instant('retainedDocuments.attachmentDownloadError'));
    }

    const domain = gcsLinkSplit[4];
    const libraryId = gcsLinkSplit[5];
    const classId = gcsLinkSplit[6];
    const documentId = gcsLinkSplit[7];
    const objectId = gcsLinkSplit[8];
    this.objectStorageService.getDownloadLink(domain, libraryId, classId, documentId, objectId).subscribe((result: OssDownloadResult) => {
        this.loadingSubject.next(false);
        window.open(result.downloadLink, '_blank');
      },
      (error) => {
        this.loadingSubject.next(false);
        this.dialogService.error(error?.error?.error?.message ?? error.message);
      });
  }

  downloadAll(): void {
    for (const attachment of this.document.attachments) {
      this.getDownloadLink(attachment);
    }
  }

  attachmentDefinition(attachment: AODocsAttachmentEntity): any {
    return AttachmentTypes.filter(type => type.mimeTypes.includes(attachment.contentType))[0] || AttachmentTypes.filter(type => type.name === 'OTHER')[0];
  }

  attachmentIcon(attachment: AODocsAttachmentEntity): string {
    return this.attachmentDefinition(attachment).icon;
  }

  close(): void {
    this.closeSubject.next(true);
  }
}
