import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Subject} from 'rxjs';
import {LibraryDetails} from '../../../models/library.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {RetentionService} from '../../../services/retention.service';
import {BindRetentionsCommand, Policy} from '../../../models/retention.model';
import {DialogService} from '../../../services/dialog.service';
import {Folder} from '../../../models/folder.model';
import {ChipsComponent} from '../../../components/chips/chips.component';
import {FolderService} from '../../../services/folder.service';
import {Router} from '@angular/router';
import {DOMAIN_FEATURES_PARAM, FINRA_DOMAIN_FEATURE} from '../../../constants/policy-assign-constants';

@Component({
  selector: 'app-policy-assigner',
  templateUrl: './policy-assigner.component.html',
  styleUrls: ['./policy-assigner.component.scss']
})
export class PolicyAssignerComponent implements OnInit, OnDestroy {
  public folderFormGroup: FormGroup;
  public policiesFormGroup: FormGroup;
  public selectedFolderSubject = new Subject<{ folder: Folder, path: string }>();
  public selectedPoliciesFromChipSubject = new Subject<Policy[]>();
  public clearPolicyChipsSubject = new Subject<void>();
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public activePolicies: Policy[];
  public preSelectedPolicies: Policy[];
  public selectedFolder: Folder;
  public path: string;
  public policiesInSelectedFolder: Policy[] = [];
  public isSelectedPoliciesNotEmpty = false;
  @ViewChild(ChipsComponent) chips: ChipsComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { libraryDetails: LibraryDetails },
              private dialogRef: MatDialogRef<PolicyAssignerComponent>,
              private formBuilder: FormBuilder,
              private retentionService: RetentionService,
              private router: Router,
              private folderService: FolderService,
              private dialogService: DialogService) {
  }

  ngOnInit(): void {
    this.getActivePolicyList();
    this.initForms();
    this.initSubscriptions();

  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }


  initForms(): void {
    this.initFolderForm();
    this.initPoliciesForm();
  }

  initFolderForm(): void {
    this.folderFormGroup = this.formBuilder.group({
      folder: ['', Validators.required]
    });
  }

  initPoliciesForm(): void {
    this.policiesFormGroup = this.formBuilder.group({
      schedules: [[], Validators.required]
    });
  }

  getActivePolicyList(): void {
    this.loadingSubject.next(true);

    let finraFlagHttpPram = this.getLibraryAssignedPolicyTypes();
    (finraFlagHttpPram == null ? this.retentionService.active() : this.retentionService.activeFinraFiltered(finraFlagHttpPram))
      .subscribe({
        next: (resp) => {
          this.activePolicies = resp.items;
          this.setPreSelectedPolicies();
          this.loadingSubject.next(false);
        },
        error: (error) => {
          this.loadingSubject.next(false);
          this.dialogService.error(error?.error?.error?.message ?? error.message);
        }
      });
  }

  private getAssignedPolicyIds(): string[] {
    return this.data.libraryDetails.retentionBindings ? this.data.libraryDetails.retentionBindings.map((item) => item.retentionId) : [];
  }


  private initSubscriptions(): void {
    this.initSelectedFolderSubscription();
    this.initSelectedPolicySubscription();
  }

  private initSelectedFolderSubscription(): void {
    this.selectedFolderSubject.subscribe((folderPikerResult) => {
      this.selectedFolder = folderPikerResult.folder;
      this.path = folderPikerResult.path;
      this.getPoliciesInSelectedFolder();
      this.setPreSelectedPolicies(this.policiesInSelectedFolder);
      this.clearPolicyChipsSubject.next();
    });
  }

  private initSelectedPolicySubscription(): void {
    this.selectedPoliciesFromChipSubject.subscribe((selectedList) => {
      this.setIsSelectedPoliciesEmpty();
      this.setPreSelectedPolicies(selectedList);
    });
  }

  private unsubscribe(): void {
    this.selectedFolderSubject.unsubscribe();
    this.selectedPoliciesFromChipSubject.unsubscribe();
  }

  public getPoliciesInSelectedFolder(): void {
    if (this.getAssignedPolicyIds().length > 0) {
      const array: Policy[] = [];
      const policiesInSelectedFolderIds = this.data.libraryDetails.retentionBindings
        .filter(binding => binding.folderId === this.selectedFolder.id)
        .map(id => ({id: id.retentionId}));
      this.activePolicies.forEach(active => {
        policiesInSelectedFolderIds.forEach(selected => {
          if (active.id === selected.id) {
            array.push(active);
          }
        });
      });
      this.policiesInSelectedFolder = [...array];
    }
  }

  public bind(): void {
    this.loadingSubject.next(true);

    const selectedPolicies: string[] = this.chips.newlyAddedItems.map((item) => item.id);
    const bindRetentionCommand: BindRetentionsCommand = {
      retentionsId: selectedPolicies,
      existingBindingTimestamp: null
    };
    this.folderService.bind(this.data.libraryDetails.libraryId, this.selectedFolder.id, bindRetentionCommand).subscribe({
      next: () => {
        this.loadingSubject.next(false);
        this.dialogRef.close(true);
      },
      error: (error) => {
        this.loadingSubject.next(false);
        this.dialogService.error(error?.error?.error?.message ?? error.message);
      }
    });
  }

  public setPreSelectedPolicies(selectedArray?: Policy[]): void {
    if (this.activePolicies) {
      if (selectedArray) {
        if ((selectedArray.filter((item) => !item.metadataBased).length > 0
          || this.policiesInSelectedFolder.filter((item) => !item.metadataBased).length > 0) && this.isRoot()) {
          this.preSelectedPolicies = [...this.activePolicies.filter(item => item.metadataBased)];
          return;
        }
        if (selectedArray.length > 0 && !this.isRoot()) {
          this.preSelectedPolicies = [];
          return;
        }
      }
      this.preSelectedPolicies = this.isRoot() ? [...this.activePolicies] : [...this.activePolicies.filter(item => !item.metadataBased)];
    }
  }

  public isRoot(): boolean {
    return this.selectedFolder ? this.data.libraryDetails.rootFolderId === this.selectedFolder.id : false;
  }

  public setIsSelectedPoliciesEmpty(): void {
    this.isSelectedPoliciesNotEmpty = this.chips ? this.chips.newlyAddedItems.length > 0 : false;
  }

  private getLibraryAssignedPolicyTypes(): Map<string, string> {
    if (this.data.libraryDetails.retentionBindings == null || this.data.libraryDetails.retentionBindings.length == 0) {
      return null;
    }
    const assignedFinraPolicy = this.data.libraryDetails.retentionBindings.filter(item => item.finraRetentionPolicy).length;
    return !!assignedFinraPolicy ? new Map().set(DOMAIN_FEATURES_PARAM, FINRA_DOMAIN_FEATURE) : new Map();
  }

  public illegalPolicySelectionState(): boolean {
    return !!this.chips && !!this.chips.newlyAddedItems && this.chips.newlyAddedItems.filter(policy => policy.finra).length > 0
      && this.chips.newlyAddedItems.filter(policy => !policy.finra).length > 0;
  }

}
