import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from './services/auth.service';
import {Subscription} from 'rxjs';
import {I18nService} from './services/i18n.service';
import {Profile} from './models/gsi-profile.model';
import {UserService} from './services/user.service';
import {User} from './models/user.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  profileSubscription: Subscription;
  userSubscription: Subscription;
  profile: Profile;
  user: User;

  constructor(public authService: AuthService,
              private userService: UserService,
              private cdr: ChangeDetectorRef,
              public i18nService: I18nService) {
    this.i18nService.initTranslateService();
    this.initProfileListener();
    this.initUserListener();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.profileSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
  }

  initProfileListener(): void {
    this.profileSubscription = this.authService.profileSubject.subscribe((evt) => {
      this.setProfile(evt.profile);
    });
  }

  initUserListener(): void {
    this.userSubscription = this.userService.userSubject.subscribe((user) => {
      this.user = user;
    });
  }

  setProfile(profile: Profile): void {
    this.profile = profile;
    this.cdr.detectChanges();
  }
}
