import {Component, NgZone, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {DialogService} from '../../../services/dialog.service';
import {BehaviorSubject} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {UploadErrorData} from '../../../models/upload-error-data.model';
import {UploadErrorsService} from '../../../services/upload-errors.service';
import {UserService} from '../../../services/user.service';
import {environment} from '../../../../environments/environment';
import {BlobUploadInfo} from '../../../models/gcs-blob.model';
import {CustomSnackBarService} from '../../../services/custom-snackbar.service';
import {TranslateService} from '@ngx-translate/core';
import {DEFAULT_UPLOAD_ERRORS_ITEMS_PAGE_NUMBER} from '../../../constants/upload-errors-constants';
import {AuthService} from '../../../services/auth.service';
import {Scope} from '../../../constants/scopes';
import {ActivatedRoute} from '@angular/router';
import {Callback} from '../../../models/callback.model';

@Component({
  selector: 'app-upload-errors',
  templateUrl: './upload-errors.component.html',
  styleUrls: ['./upload-errors.component.scss']
})
export class UploadErrorsComponent implements OnInit {

  clientId = environment.clientId;
  scopes = [Scope.EMAIL, Scope.PROFILE, Scope.OPENID, Scope.STORAGE].join(' ');
  requiresNewScope = false;
  public uploadErrorsCount: number;
  public loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public dataSource: MatTableDataSource<UploadErrorData> = new MatTableDataSource();

  public columns = [
    {name: 'attachment', show: true},
    {name: 'document', show: true},
    {name: 'step1', show: true},
    {name: 'step2', show: true}
  ];

  constructor(private formBuilder: FormBuilder,
              private ngZone: NgZone,
              private activatedRoute: ActivatedRoute,
              private authService: AuthService,
              private userService: UserService,
              private uploadErrorsService: UploadErrorsService,
              private customSnackBarService: CustomSnackBarService,
              private translate: TranslateService,
              private dialogService: DialogService) {
  }

  async ngOnInit(): Promise<void> {
    this.list();
    const queryParams = this.activatedRoute.snapshot.queryParamMap;
    const isCallback = queryParams.get('callback') ?? undefined;
    if (isCallback) {
      await this.manageCallback();
    }
  }

  list(): void {
    this.loadingSubject.next(true);
    this.uploadErrorsService.list().subscribe({
      next: (resp) => {
        this.dataSource.data = resp.items;
        this.uploadErrorsCount = this.dataSource.data.length;
        this.loadingSubject.next(false);
      }, error: (error) => {
        this.loadingSubject.next(false);
        this.dialogService.error(error?.error?.error?.message ?? error.message);
      }
    });
  }

  async upload(event: Event, element: UploadErrorData): Promise<void> {
    const scopes = this.authService.getScopes();
    if (!scopes.includes(Scope.STORAGE)) {
      this.uploadErrorsService.setCallbackData({event, element});
      this.requiresNewScope = true;
    } else {
      this.loadingSubject.next(true);
      const getUploadErrorBucketResponse = await this.uploadErrorsService.getUploadErrorBucket().toPromise();
      this.uploadErrorsService.insertObject(getUploadErrorBucketResponse.bucketName, event).subscribe({
        next: (resp) => {
          (event.target as HTMLButtonElement).disabled = true;
          this.loadingSubject.next(false);
          this.customSnackBarService.openSuccessSnackbar(this.translate.instant('holds.upload.uploadStartedMessage'));
          this.triggerCopy(resp, event, element);
        }, error: (error) => {
          this.loadingSubject.next(false);
          this.dialogService.error(error?.error?.error?.message ?? error.message);
        }
      });
    }
  }

  triggerCopy(uploadResponse: BlobUploadInfo, event: Event, attachment: UploadErrorData): void {
    this.uploadErrorsService.copy(uploadResponse, attachment).subscribe({
      next: () => this.loadingSubject.next(false),
      error: (error) => {
        console.log(error);
        (event.target as HTMLButtonElement).disabled = false;
        this.loadingSubject.next(false);
        this.dialogService.error(error?.error?.error?.message ?? error.message);
      }
    });
  }

  displayedColumns(): string[] {
    return this.columns.filter(column => column.show).map(column => column.name);
  }

  openDocumentPage(document: UploadErrorData): void {
    const url = this.documentLink(document);
    window.open(url, '_blank').focus();
  }

  documentLink(document: UploadErrorData): string {
    const aodocsUrl = environment.aodocsUrl;
    const domain = this.userService.user.domain;
    return aodocsUrl + '?aodocs-domain=' + domain + '#Menu_viewDoc/LibraryId_' + document.libraryId + '/DocumentId_' + document.documentId;
  }

  public getPageSize(): number {
    return DEFAULT_UPLOAD_ERRORS_ITEMS_PAGE_NUMBER;
  }

  googleLoginSuccess(event: any): void {
    try {
      const callback: Callback = {url: 'upload', params: {callback: true}};
      this.authService.signInWithGoogle(event.detail, this.scopes, callback);
    } catch (e: any) {
      this.authService.authErrorMessage$.next(e.error);
    }
  }

  async manageCallback(): Promise<void> {
     const callbackData = this.uploadErrorsService.getCallbackData();
     if (!!callbackData) {
        await this.upload(callbackData.event, callbackData.element);
        this.uploadErrorsService.clearCallbackData();
      }
  }

  loginError(): void {
    this.authService.authErrorMessage$.next('Error: An error occurred during authentication');
  }
}
