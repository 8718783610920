<section class="main-content">
  <h1>{{'title.'+pageTitle | translate}}</h1>
  <mat-card class="no-padding">
    <section class="filter-section" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="loaded">
      <section fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxFlex="40" fxFlex.lt-md="60" class="search-input-container">
       <app-search-input [valueSubject]="searchValueSubject" [clearSubject]="searchClearSubject" [ngClass.lt-md]="'marg-bot'"></app-search-input>
        <app-library-tag-selector  *ngIf="!libraryActiveTab" [valueSubject]="filterValueSubject" [clearSubject]="filterClearSubject"></app-library-tag-selector>
      </section>
      <button mat-icon-button (click)="refresh()" class="refresh-btn"
              matTooltip="{{'libraries.table.tooltips.refresh' | translate}}">
        <mat-icon>refresh</mat-icon>
      </button>
    </section>
    <div class="spinner-container" *ngIf="dataSource.loading$ | async">
      <mat-spinner></mat-spinner>
    </div>
    <table mat-table [dataSource]="dataSource" id="libraries-table" class="mat-elevation-z8" [hidden]="dataSource.loading$ | async">
      <!-- name -->
      <ng-container matColumnDef="displayName">
        <mat-header-cell *matHeaderCellDef>{{'libraries.table.displayName' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let element"><span> {{element.displayName}}</span> </mat-cell>
      </ng-container>

      <!-- type -->
      <ng-container matColumnDef="libraryType">
        <mat-header-cell *matHeaderCellDef>{{'libraries.table.libraryType' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.libraryType}} </mat-cell>
      </ng-container>

      <!-- config status -->
      <ng-container matColumnDef="configurationStatus" *ngIf="!libraryActiveTab">
        <mat-header-cell mat-header-cell *matHeaderCellDef>{{'libraries.table.configurationStatus' | translate}}</mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element"
                  [ngClass]="element.libraryTag == 'UNBOUND' ? 'not-config' : 'config-error'" >
          <span>   {{'libraries.configurationStatus.' + element.libraryTag | translate}} </span>
        </mat-cell>
      </ng-container>

      <!-- binding num -->
      <ng-container matColumnDef="numberOfRetentionBindings">
        <mat-header-cell *matHeaderCellDef><span>{{'libraries.table.numberOfRetentionBindings' | translate}}</span></mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.numberOfRetentionBindings}} </mat-cell>
      </ng-container>

      <!-- edit -->
      <ng-container matColumnDef="edit">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-button mat-icon-button (click)="edit(element.libraryId)"
                  matTooltip="{{'libraries.table.tooltips.editRetention' | translate}}" class="table-button">
            <mat-icon>edit</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <!-- link -->
      <ng-container matColumnDef="link">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element">
          <a mat-button mat-icon-button [href]="getLibrarySettingsUrl(element.libraryId)"
             target="_blank"  matTooltip="{{'libraries.table.tooltips.libraryLink' | translate}}" class="table-button">
            <mat-icon>settings</mat-icon>
          </a>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">{{"dataNotFound" | translate}}</td>
      </tr>
    </table>
    <section [hidden]="dataSource.loading$ | async">
      <mat-paginator  [length]="librariesCount" [pageSize]="getPageSize()" [showFirstLastButtons]="false" [pageSizeOptions]="[10,20,30]"></mat-paginator>
    </section>
  </mat-card>
</section>
