<mat-card>
  <section class="path-container" *ngIf="currentFolder">
    <h3>
      <span *ngFor="let item of path; let idx = index">
        <span class="first-grey" *ngIf="idx > 0"> > </span>
        <span (click)="gotoPath(item)" class="path-item" [ngClass]="{path_first: idx === 0}">
          {{item.name}}
        </span>
      </span>
    </h3>
  </section>
  <section>
    <button mat-icon-button (click)="navigate()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <mat-label>{{'libraries.edit.assigner.step1.name'|translate}}</mat-label>
    <div class="spinner-container" *ngIf="loading$ | async">
      <mat-spinner></mat-spinner>
    </div>
    <section [hidden]="loading$ | async">
      <mat-list class="folder-list">
        <mat-divider></mat-divider>
        <mat-list-item *ngFor="let child of currentFolderChildren">
          <section (click)="navigate(child.id)" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon>folder</mat-icon>
            <span>{{child.name}}</span>
          </section>
          <mat-divider></mat-divider>
        </mat-list-item>
      </mat-list>
      <section *ngIf="currentFolderChildren.length === 0">
        <p class="no-sub-folders grey-label">{{'libraries.edit.assigner.step1.no_subfolders'|translate}}</p>
      </section>
    </section>
  </section>
</mat-card>
