import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {PoliciesListComponent} from './list/policies-list.component';

const routes: Routes = [
  {
    path: '',
    component: PoliciesListComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PoliciesRoutingModule {
}
