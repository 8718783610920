import {UserClassification} from '../../constants/user-constants';

export const RoutePermissions = {
  libraries: [
    UserClassification.AODOCS_SUPER_ADMIN,
    UserClassification.RETENTION_ADMIN,
    UserClassification.USER
  ],
  policies: [
    UserClassification.AODOCS_SUPER_ADMIN,
    UserClassification.RETENTION_ADMIN,
    UserClassification.USER
  ],
  reports: [
    UserClassification.AODOCS_SUPER_ADMIN,
    UserClassification.RETENTION_ADMIN,
    UserClassification.USER
  ],
  audit: [
    UserClassification.AODOCS_SUPER_ADMIN,
    UserClassification.RETENTION_ADMIN,
    UserClassification.AUDITOR
  ],
  holds: [
    UserClassification.AODOCS_SUPER_ADMIN,
    UserClassification.RETENTION_ADMIN,
    UserClassification.USER
  ],
  legal: [
    UserClassification.AODOCS_SUPER_ADMIN,
    UserClassification.RETENTION_ADMIN,
    UserClassification.USER,
    UserClassification.AUDITOR
  ],
  upload: [
    UserClassification.AODOCS_SUPER_ADMIN,
    UserClassification.RETENTION_ADMIN,
    UserClassification.AUDITOR
  ],
  settings: [
    UserClassification.AODOCS_SUPER_ADMIN,
    UserClassification.RETENTION_ADMIN,
    UserClassification.USER
  ],
};
