<mat-form-field class="chip-list" appearance="fill">
  <mat-label>{{label}}</mat-label>
  <mat-chip-list #chipList aria-label="Selection">
    <mat-chip
      *ngFor="let item of selectedItems"
      [selectable]="selectable"
      [removable]="isRemovable(item)"
      (removed)="remove(item)">
      {{item.id}}
      <mat-icon matChipRemove *ngIf="isRemovable(item)">cancel</mat-icon>
    </mat-chip>
    <input
      placeholder="New item..."
      #input
      [formControl]="formCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)"
      [readOnly]="readonly || (allItems && allItems.length === 0)">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let item of filtered | async" [value]="item">
      {{item.id}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
