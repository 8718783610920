import {LibraryStatus} from '../models/library.model';

export enum LibraryTagFilter {
  ACTIVE = 'RETENTION_SCHEDULE_ACTIVE',
  INACTIVE = 'EXCLUDE_RETENTION_SCHEDULE_ACTIVE',
  INACTIVE_WITH_ERRORS = 'RETENTION_SCHEDULE_ASSIGNED',
  INACTIVE_NOT_CONFIGURED = 'EXCLUDE_RETENTION_SCHEDULE',
  ALL = 'UNBOUND'
}


export enum LibraryTag {
  RETENTION_SCHEDULE_ACTIVE = 'Active',
  RETENTION_SCHEDULE_ASSIGNED = 'Configuration Error',
  UNBOUND = 'Not Configured',
}

export const DEFAULT_LIBRARIES_ITEMS_PAGE_NUMBER = 10;

export const LibraryStatusList: LibraryStatus[]  = [
  {value: LibraryTagFilter.INACTIVE, viewValue: 'All Statuses'},
  {value: LibraryTagFilter.INACTIVE_NOT_CONFIGURED, viewValue: 'Not Configured'},
  {value: LibraryTagFilter.INACTIVE_WITH_ERRORS, viewValue: 'Configuration Error'}
];
