import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReportsRoutingModule} from './reports-routing.module';
import {ReportsDeletedDocsComponent} from './deleted-docs/reports-deleted-docs.component';
import {ReportsDeletedDocsDetailsComponent} from './deleted-docs/deleted-docs-details/reports-deleted-docs-details.component';
import {ReportsToBeDeletedDocsComponent} from './to-be-deleted-docs/reports-to-be-deleted-docs.component';
import {ReportsAssignmentComponent} from './assignment/reports-assignment.component';
import {MatCardModule} from '@angular/material/card';
import {TranslateModule} from '@ngx-translate/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {ReactiveFormsModule} from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {FlexModule} from '@angular/flex-layout';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RetainedDocumentsComponent} from '../audit/retained-documents/retained-documents.component';


@NgModule({
  declarations: [ReportsDeletedDocsComponent,
    ReportsAssignmentComponent,
    ReportsToBeDeletedDocsComponent,
    ReportsDeletedDocsDetailsComponent,
    RetainedDocumentsComponent
  ],
  imports: [
    CommonModule,
    ReportsRoutingModule,
    MatCardModule,
    TranslateModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatButtonModule,
    FlexModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatTooltipModule,
    TranslateModule.forChild({
      extend: true
    })
  ]
})
export class ReportsModule {
}
