<button mat-icon-button class="close-button" [mat-dialog-close]="false">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h2 mat-dialog-title class="header">{{"policies.editor.title" | translate}} {{data.id}}</h2>
<mat-dialog-content id="creation-dialog-content" class="mat-typography">
  <div class="spinner-container" *ngIf="loading$ | async">
    <mat-spinner></mat-spinner>
  </div>
  <section [hidden]="loading$ | async">
    <mat-tab-group mat-stretch-tabs id="retention-editor-tabs" class="example-stretched-tabs">
      <mat-tab label="IDENTIFICATION">
        <div class="horizontal-content-container">
          <form [formGroup]="identificationFormGroup">
            <p class="grey-label label-margin">{{"policies.editor.tabs.identification.descriptions.id"| translate}}</p>
            <div fxLayout="row" fxLayoutAlign="space-between center">

              <mat-form-field appearance="fill" fxFlex="48">
                <mat-label>{{"policies.editor.tabs.identification.labels.policyCode"| translate}}</mat-label>
                <input matInput formControlName="code" required autocomplete="off">
              </mat-form-field>
              <mat-form-field appearance="fill" fxFlex="48">
                <mat-label>{{"policies.editor.tabs.identification.labels.country"| translate}}</mat-label>
                <input matInput formControlName="country" required autocomplete="off">
              </mat-form-field>
            </div>

            <p class="grey-label label-margin">{{"policies.editor.tabs.identification.descriptions.optional"| translate}}</p>
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <mat-form-field appearance="fill" fxFlex="48">
                <mat-label>{{"policies.editor.tabs.identification.labels.function"| translate}}</mat-label>
                <input matInput formControlName="function" autocomplete="off">
              </mat-form-field>
              <mat-form-field appearance="fill" fxFlex="48">
                <mat-label>{{"policies.editor.tabs.identification.labels.sub_function"| translate}}</mat-label>
                <input matInput formControlName="subFunction" autocomplete="off">
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center">
              <mat-form-field appearance="fill" fxFlex="48">
                <mat-label>{{"policies.editor.tabs.identification.labels.category"| translate}}</mat-label>
                <input matInput formControlName="category" autocomplete="off">
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center">
              <mat-form-field appearance="fill" fxFlex="100">
                <mat-label>{{"policies.editor.tabs.identification.labels.description"| translate}}</mat-label>
                <input matInput formControlName="description" autocomplete="off">
              </mat-form-field>
            </div>

          </form>
        </div>
      </mat-tab>

      <mat-tab label="SETUP">
        <div class="horizontal-content-container">
          <form [formGroup]="setupFormGroup">

            <ng-template matStepLabel>{{"policies.editor.tabs.setup.title"| translate}}</ng-template>
            <div fxLayout="row" fxLayoutAlign="start center">
              <p class="grey-label label-margin">{{"policies.editor.tabs.setup.descriptions.method"| translate}} </p>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <mat-radio-group aria-label="Select an option" formControlName="method" fxLayout="column"
                               fxLayoutAlign="space-between none"
                               (change)="methodChanged($event)">
                <mat-radio-button
                  [value]="retentionMethod.CREATION_DATE">{{"policies.editor.tabs.setup.labels.creation_date"| translate}}
                  :
                  <span
                    class="radio-description">{{"policies.editor.tabs.setup.labels.creation_date_text"| translate}}</span>
                </mat-radio-button>
                <mat-radio-button
                  [value]="retentionMethod.MODIFICATION_DATE">{{"policies.editor.tabs.setup.labels.modification_date"| translate}}
                  :
                  <span
                    class="radio-description">{{"policies.editor.tabs.setup.labels.modification_date_text"| translate}}</span>
                </mat-radio-button>
                <mat-radio-button
                  [value]="retentionMethod.NOT_CLASSIFIED">{{"policies.editor.tabs.setup.labels.not_classified"| translate}}
                  :
                  <span
                    class="radio-description">{{"policies.editor.tabs.setup.labels.not_classified_text"| translate}}</span>
                </mat-radio-button>
                <mat-radio-button
                  [value]="retentionMethod.CUSTOM_EVENT">{{"policies.editor.tabs.setup.labels.custom_event"| translate}}
                  :
                  <span
                    class="radio-description">{{"policies.editor.tabs.setup.labels.custom_event_text"| translate}}</span>
                </mat-radio-button>
              </mat-radio-group>
            </div>

            <p class="grey-label label-margin">{{"policies.editor.tabs.setup.descriptions.period"| translate}}</p>
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div fxLayout="row" fxLayoutAlign="start center" fxFlex="48">
                <mat-form-field appearance="fill">
                  <mat-label>{{"policies.editor.tabs.setup.labels.time"| translate}}</mat-label>
                  <input matInput formControlName="time" type="number" min="-1" required>
                </mat-form-field>
                <span class="year-margin">{{"policies.editor.tabs.setup.labels.years"| translate}}</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxFlex="48">
                <mat-form-field appearance="fill">
                  <mat-label>{{"policies.editor.tabs.setup.labels.offset"| translate}}</mat-label>
                  <input matInput formControlName="offset" type="number" min="0">
                </mat-form-field>
                <div fxLayout="row" fxLayoutAlign="start" class="tooltip-container">
                  <span class="year-margin">{{"policies.editor.tabs.setup.labels.years"|translate}}</span>
                  <mat-icon class="tooltip-icon" matTooltipClass="line-broken-tooltip" [matTooltip]="offsetToolTip()">
                    help_outline
                  </mat-icon>
                </div>
              </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div fxLayout="column" fxFlex="48">
                <p class="grey-label label-margin">{{"policies.editor.tabs.setup.descriptions.event"| translate}}</p>
                <mat-form-field appearance="fill">
                  <mat-label>{{"policies.editor.tabs.setup.labels.event"| translate}}</mat-label>
                  <input matInput formControlName="event" autocomplete="off">
                </mat-form-field>
              </div>

              <div fxLayout="column" fxFlex="48">
                <p class="grey-label label-margin">{{"policies.editor.tabs.setup.labels.second_label"| translate}}</p>
                <mat-form-field appearance="fill">
                  <mat-label>{{"policies.editor.tabs.setup.labels.second_label"| translate}}</mat-label>
                  <input matInput formControlName="secondaryLabel" autocomplete="off">
                </mat-form-field>
              </div>
            </div>

          </form>
        </div>
      </mat-tab>

      <mat-tab label="OPTIONS">
        <div class="horizontal-content-container">

          <form [formGroup]="optionsFormGroup">
            <p class="grey-label label-margin">{{"policies.editor.tabs.options.descriptions.activate"| translate}}</p>
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <mat-slide-toggle [(ngModel)]="policy.active" formControlName="active" (click)="confirmActivateDeactivate($event)">
                {{"policies.editor.tabs.options.labels.active"| translate}}
                {{"policies.editor.tabs.options.labels." + getActiveText() | translate}}
              </mat-slide-toggle>
            </div>

            <div *ngIf="!isRetentionMetadataFieldsHidden()">
              <p class="grey-label label-margin">{{"policies.editor.tabs.options.descriptions.rules"| translate}}</p>
              <div class="metadata-container" fxLayout="row" fxLayoutAlign="space-between start">
                <div formArrayName="metadata" fxFlex="100">
                  <div *ngFor="let data of metadata.controls; let i = index; last as isLast;"
                       fxLayout="row" fxLayoutAlign="space-between center"
                       [formGroupName]="i">

                    <mat-form-field appearance="fill" fxFlex="45">
                      <mat-label>{{"policies.editor.tabs.options.labels.property"| translate}}</mat-label>
                      <input matInput formControlName="property" placeholder="Property" autocomplete="off">
                    </mat-form-field>
                    <mat-form-field appearance="fill" fxFlex="45">
                      <mat-label>{{"policies.editor.tabs.options.labels.value"| translate}}</mat-label>
                      <input matInput formControlName="value" placeholder="Value" autocomplete="off">
                    </mat-form-field>

                    <div *ngIf="!isRetentionUsed()" fxLayout="row" fxFlex.lt-sm="100" fxLayoutAlign.lt-sm="end center">
                      <button mat-icon-button aria-label="Remove metadata" *ngIf="!isLast" type="button" (click)="removeMetadata(i)">
                        <mat-icon>remove</mat-icon>
                      </button>
                      <button *ngIf="isLast" mat-icon-button aria-label="Add metadata" type="button" (click)="addMetadata()"
                              [disabled]="!data.valid || metadata.errors !== null">
                        <mat-icon>add</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="isRetentionMetadataFieldsHidden()" class="empty-metadata-container"
                 fxLayout="row" fxLayoutAlign="space-between start">
            </div>

          </form>
        </div>
      </mat-tab>
    </mat-tab-group>

    <section align="end" class="fixed-buttons-margins">
      <button mat-button [mat-dialog-close]="false">
        {{"policies.editor.cancel"| translate}}
      </button>
      <button mat-button class="blue-label fw-500" (click)="confirmCreate()" [disabled]="!optionsFormGroup.valid || !setupFormGroup.valid">
        {{"policies.editor.save"| translate}}
      </button>
    </section>

  </section>
</mat-dialog-content>

