<section fxLayout="row" fxLayoutAlign="space-between center">
  <form [formGroup]="form" class="form-container">
    <mat-form-field appearance="fill" fxFlex="42" class="type-selector">
      <mat-label>{{"legalHold.creator.labels.property"|translate}}</mat-label>
      <mat-select formControlName="property">
        <mat-option *ngFor="let item of legalHoldRulesTypeToSelect$" [value]="item.value"
                    (onSelectionChange)="propertySelected($event)">
          {{item.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" fxFlex="42" *ngIf="isTextField()">
      <mat-label>{{"legalHold.creator.labels.value"|translate}}</mat-label>
      <input matInput formControlName="value" placeholder="Value" autocomplete="off" required>
    </mat-form-field>
    <mat-form-field appearance="fill" fxFlex="42" *ngIf="isEmail()">
      <mat-label>{{"legalHold.creator.labels.value"|translate}}</mat-label>
      <input matInput type="email" email formControlName="value" placeholder="Value" autocomplete="off" required>
    </mat-form-field>
    <mat-form-field appearance="fill" fxFlex="42" *ngIf="isDatePicker()">
      <mat-date-range-input formGroupName="rangePicker" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Start date" required>
        <input matEndDate formControlName="end" placeholder="End date" required>
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <button mat-icon-button aria-label="Remove metadata"  type="button" (click)="remove()">
      <mat-icon>remove</mat-icon>
    </button>
  </form>
</section>
