import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormControl} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MatChipInputEvent} from '@angular/material/chips';

@Component({
  selector: 'app-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss']
})
export class ChipsComponent implements OnInit {
  selectable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  formCtrl = new FormControl();
  filtered: Observable<any[]>;
  selectedItems$: any[];
  existingItems$: any[];
  newlyAddedItems$: any[] = [];
  allItems$: any[];
  readonly$: boolean;
  @Input() label: string;
  @Input() selectedEventSubject: Subject<any[]>;
  @Input() clearSubject: Subject<void>;
  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor() {
  }

  @Input() set allItems(value: any[]) {
    this.allItems$ = value;
  }

  get allItems(): any[] {
    return this.allItems$;
  }

  @Input() set selectedItems(value: any[]) {
    this.selectedItems$ = [...value];
    this.existingItems$ = [...this.selectedItems$];
  }

  get selectedItems(): any[] {
    return this.selectedItems$;
  }

  set newlyAddedItems(value: any[]) {
    this.newlyAddedItems$ = [...value];
  }

  get newlyAddedItems(): any[] {
    return this.newlyAddedItems$;
  }

  @Input() set readonly(value: boolean) {
    this.readonly$ = value;
  }

  get readonly(): boolean {
    return this.readonly$;
  }

  ngOnInit(): void {
    this.clearSubject.subscribe(() => this.selectedItems$ = []);
    this.filtered = this.formCtrl.valueChanges.pipe(
      startWith(null),
      map((item: any | null) => {
        if (typeof item === 'string') {
          return item ? this._filter(item) : this.allItems$.slice();
        }
      }));
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '')
      && (this.allItems$.filter(item => item.id === value).length > 0)
      && (this.selectedItems$.filter(item => item.id === value).length === 0)) {
      const fullValue = this.allItems$.filter(item => item.id === value)[0];
      this.selectedItems$.push(fullValue);
      this.newlyAddedItems$.push(fullValue);
      this.selectedEventSubject.next(this.newlyAddedItems$);

    }
    if (input) {
      input.value = '';
    }

    this.formCtrl.setValue(null);
  }

  remove(item: string): void {
    const index = this.selectedItems$.indexOf(item);
    if (index >= 0) {
      this.selectedItems$.splice(index, 1);
      this.newlyAddedItems$.splice(index, 1);
      this.selectedEventSubject.next(this.newlyAddedItems$);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if ((this.selectedItems$.filter(item => item.id === event.option.value.id).length === 0)) {
      this.selectedItems$.push(event.option.value);
      this.newlyAddedItems$.push(event.option.value);
      this.selectedEventSubject.next(this.newlyAddedItems$);
    }
    this.input.nativeElement.value = '';
    this.formCtrl.setValue(null);
  }

  private _filter(value: string): any[] {
    return this.allItems$.filter(item => item.id.toLowerCase().includes(value.toLowerCase().trim()));
  }

  isRemovable(item: any): boolean {
    return this.existingItems$.filter((i) => i.id === item.id).length === 0;
  }

}
