import {Component, Inject, NgZone, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogService} from '../../../../services/dialog.service';
import {DatePipe} from '@angular/common';
import {LegalHold} from '../../../../models/legal-hold';
import {LegalHoldService} from '../../../../services/legal-hold.service';
import {LegalHoldRulesType} from '../../../../constants/legal-hold-constants';
import {UserService} from '../../../../services/user.service';

@Component({
  selector: 'app-legal-hold-releaser',
  templateUrl: './legal-hold-releaser.component.html',
  styleUrls: ['./legal-hold-releaser.component.scss']
})
export class LegalHoldReleaserComponent implements OnInit {

  public loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public legalHold: LegalHold;
  public rules = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              private holdService: LegalHoldService,
              private ngZone: NgZone,
              private userService: UserService,
              private dialogService: DialogService,
              private datePipe: DatePipe,
              private dialogRef: MatDialogRef<LegalHoldReleaserComponent>) {
    this.legalHold = data;
    this.convertRules(Object.entries(this.legalHold.rules));
  }

  ngOnInit(): void {
  }

  release(): void {
    this.holdService.release(this.legalHold.id).subscribe({
      next: () => {
        this.loadingSubject.next(false);
        this.dialogRef.close(true);
      },
      error: error => {
        this.loadingSubject.next(false);
        this.ngZone.run(() => this.dialogService.error(error.result.error.message));
        this.dialogRef.close(false);
      }
    });
  }

  documents(): number {
    return this.legalHold.documents ? this.legalHold.documents.length : 0;
  }

  convertRules(arrayToTransform: any[]): void {
    arrayToTransform.forEach(rule => {
      const obj = {property: '', value: ''};
      switch (rule[0]) {
        case LegalHoldRulesType.LIBRARY_NAME:
          obj.property = 'Library name';
          obj.value = rule[1];
          this.rules.push(obj);
          break;
        case LegalHoldRulesType.CLASS_NAME:
          obj.property = 'Class name';
          obj.value = rule[1];
          this.rules.push(obj);
          break;
        case LegalHoldRulesType.TITLE:
          obj.property = 'Title';
          obj.value = rule[1];
          this.rules.push(obj);
          break;
        case LegalHoldRulesType.INITIAL_AUTHOR:
          obj.property = 'Initial author';
          obj.value = rule[1];
          this.rules.push(obj);
          break;
        case LegalHoldRulesType.UPDATE_AUTHOR:
          obj.property = 'Update author';
          obj.value = rule[1];
          this.rules.push(obj);
          break;
        case LegalHoldRulesType.CREATION_DATE:
          obj.property = 'Creation date';
          const temp = rule[1].split('_');
          const start = this.datePipe.transform(new Date(Number(temp[0])), 'dd/MM/yyyy');
          const end = this.datePipe.transform(new Date(Number(temp[1])), 'dd/MM/yyyy');
          obj.value = start + '-' + end;
          this.rules.push(obj);
          break;
        case LegalHoldRulesType.MODIFICATION_DATE:
          obj.property = 'Modification date';
          const tempModif = rule[1].split('_');
          const startModif = this.datePipe.transform(new Date(Number(tempModif[0])), 'dd/MM/yyyy');
          const endModif = this.datePipe.transform(new Date(Number(tempModif[1])), 'dd/MM/yyyy');
          obj.value = startModif + '-' + endModif;
          this.rules.push(obj);
          break;
      }
    });
  }

  isAuditor(): boolean {
    return this.userService.isAuditor();
  }
}
