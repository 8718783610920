import {ChangeDetectorRef, Inject, Injectable, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Policy} from '../../../models/retention.model';
import {FormBuilder, Validators} from '@angular/forms';
import {RetentionService} from '../../../services/retention.service';
import {DialogService} from '../../../services/dialog.service';
import {RetentionMethod} from '../../../constants/retention-constants';
import {BothOrNoneValidator, duplicateValidator} from '../../../validators/custom-validators';
import {PoliciesBaseComponent} from '../policies-base-component';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export abstract class PoliciesEditorComponent extends PoliciesBaseComponent implements OnInit {

  public policy: Policy;

  protected constructor(@Inject(MAT_DIALOG_DATA) public data: Policy,
                        dialogRef: MatDialogRef<PoliciesEditorComponent>,
                        formBuilder: FormBuilder,
                        cdr: ChangeDetectorRef,
                        retentionService: RetentionService,
                        dialogService: DialogService,
                        translate: TranslateService) {

    super(dialogRef, formBuilder, cdr, retentionService, dialogService, translate);
    this.policy = data;
  }

  ngOnInit(): void {
    this.initIdentificationForm();
    this.initSetupForm();
    this.initOptionsForm();
  }

  initIdentificationForm(): void {
    this.identificationFormGroup = this.formBuilder.group({
      code: [{value: this.policy.code, disabled: true}, Validators.required],
      country: [{value: this.policy.country, disabled: true}, Validators.required],
      function: [this.policy.function],
      subFunction: [this.policy.subFunction],
      category: [this.policy.recordCategory],
      description: [this.policy.description]
    });
  }

  initSetupForm(): void {
    let offsetDisabled: boolean;
    let offsetValue: number;
    let eventValue: string;

    let retentionMethod: RetentionMethod;

    if (this.policy.event === 'Creation Date') {
      retentionMethod = RetentionMethod.CREATION_DATE;
      offsetDisabled = false;
      offsetValue = this.policy.customOffset;
      eventValue = this.policy.event;

    } else if (this.policy.event === 'Modification Date') {
      retentionMethod = RetentionMethod.MODIFICATION_DATE;
      offsetDisabled = false;
      offsetValue = this.policy.customOffset;
      eventValue = this.policy.event;

    } else if (this.policy.event === 'Not Classified') {
      retentionMethod = RetentionMethod.NOT_CLASSIFIED;
      offsetDisabled = true;
      offsetValue = 1;
      eventValue = this.policy.event;

    } else {
      retentionMethod = RetentionMethod.CUSTOM_EVENT;
      offsetDisabled = true;
      offsetValue = null;
      eventValue = this.policy.event;
    }

    this.setupFormGroup = this.formBuilder.group({
      method: [{value: retentionMethod, disabled: this.isRetentionUsed()}, Validators.required],
      time: [this.policy.retentionTime, [Validators.required, Validators.min(-1)]],
      offset: [{value: offsetValue, disabled: offsetDisabled}, Validators.min(-0)],
      event: [{value: eventValue, disabled: this.isRetentionUsed()}],
      secondaryLabel: [{value: this.policy.eventLabel, disabled: this.isRetentionUsed()}]
    });
  }

  initOptionsForm(): void {
    this.optionsFormGroup = this.formBuilder.group({
      active: [this.policy.active],
      finra: [this.policy.finra],
      metadata: this.formBuilder.array(this.createMetadataGroups(), duplicateValidator())
    });
  }

  private createMetadataGroups(): any {
    let metadataGroups = [];

    if (this.policy.metadataConstraint) {
      const metadataArray = [];
      Object.keys(this.policy.metadataConstraint).forEach(key => metadataArray.push({
        property: key,
        value: this.policy.metadataConstraint[key]
      }));

      Object.keys(metadataArray).forEach(key => {
        metadataGroups.push(
          this.formBuilder.group({
            property: [{value: metadataArray[key].property, disabled: this.isRetentionUsed()}],
            value: [{value: metadataArray[key].value, disabled: this.isRetentionUsed()}],
          }, {validators: BothOrNoneValidator()})
        );
      });

      if (!this.isRetentionUsed()) {
        metadataGroups.push(
          this.formBuilder.group({
            property: [''], value: [''],
          }, {validators: BothOrNoneValidator()})
        );
      }

    } else {
      metadataGroups = [
        this.formBuilder.group({
          property: [''], value: [''],
        }, {validators: BothOrNoneValidator()})
      ];
    }

    return metadataGroups;
  }

  isRetentionUsed(): boolean {
    return this.policy.retentionUsed;
  }

  isRetentionMetadataFieldsHidden(): boolean {
    return !this.policy.metadataBased && this.policy.retentionUsed;
  }

  getActiveText(): string {
    return this.policy.active ? 'activated' : 'deactivated';
  }

  active(): boolean {
    return this.optionsFormGroup.get('active') as unknown as boolean;
  }

  // override method in PoliciesBaseComponent
  setFormControlValueCustomEvent(formControl: string, value: any): void {
    this.setupFormGroup.get(formControl).setValue(this.policy.event);
  }

  // override method in PoliciesBaseComponent
  setFormControlValueCustomOffset(formControl: string, value: any): void {
    this.setupFormGroup.get(formControl).setValue(this.policy.customOffset);
  }

  confirmActivateDeactivate(event: any): void {
    event.preventDefault();

    this.dialogService.plain(this.createDialogMessage(), this.createDialogHeader())
      .afterClosed().subscribe((choice) => {
      if (choice) {
        this.policy.active = !this.policy.active;
      }
    });
  }

  private createDialogHeader(): string {
    return this.policy.active ?
      this.translate.instant('policies.deactivationDialogHeader') :
      this.translate.instant('policies.activationDialogHeader');
  }

  private createDialogMessage(): string {
    const beginDialogMessage = this.policy.active ?
      this.translate.instant('policies.deactivationDialogMessage') :
      this.translate.instant('policies.activationDialogMessage');

    return beginDialogMessage + ' ' + this.policy.id + '?';
  }

}
