<header fxLayout="row" fxLayoutAlign="space-between center">
  <section class="title-container" fxLayout="row" fxLayoutAlign="space-between center">
    <img alt="logo" class="logo" src="assets/img/logo_white.svg">
    <mat-divider class="horizontal-divider" vertical></mat-divider>
    <h2>Retention management</h2>
  </section>
  <section fxLayout="row" fxLayoutAlign="center">
    <app-domain-viewer></app-domain-viewer>
    <app-help></app-help>
    <app-profile [profile]="profile"></app-profile>
  </section>
</header>
