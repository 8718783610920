import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {ApiVersion} from '../constants/apis';
import {SearchData} from '../models/search-data-model';
import {CrossLibrarySearchResult} from '../models/cross-library-search-result.model';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SearchService extends AbstractService {

  constructor(private http: HttpClient) {
    super();
  }

  search(query: string, searchData: SearchData[]): Observable<{ jobId: string }> {
    const url = this.toUrl('crossLibrarySearch', 'search/init', ApiVersion.V1);
    const params = {query, searchData};
    return this.http.post<{ jobId: string }>(url, params);
  }

  poll(searchParentJob: string): Observable<CrossLibrarySearchResult> {
    console.log('POLLING');
    const url = this.toUrl('crossLibrarySearch', 'search/result/' + searchParentJob, ApiVersion.V1);
    return this.http.get<CrossLibrarySearchResult>(url);
  }
}
