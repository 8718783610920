import {Component, Input, OnInit} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {FolderRetentionBinding, LibraryDetails} from '../../models/library.model';
import {FolderService} from '../../services/folder.service';
import {UnbindRetentionsCommand} from '../../models/retention.model';
import {DialogService} from '../../services/dialog.service';
import {BehaviorSubject, Subject} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {EditFolderPolicyDialogComponent} from '../edit-folder-policy-dialog/edit-folder-policy-dialog.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-assigned-retentions-table',
  templateUrl: './assigned-retentions-table.component.html',
  styleUrls: ['./assigned-retentions-table.component.scss']
})
export class AssignedRetentionsTableComponent implements OnInit {

  @Input() dataSource: FolderRetentionBinding[];
  @Input() reload: Subject<boolean>;
  @Input() libraryDetails: LibraryDetails;
  @Input() rootFolderId: string;
  @Input() libraryId: string;
  @Input() isError: boolean;
  @Input() reloadSubject: Subject<boolean>;
  columnsToDisplay = ['folderName', 'id', 'created', 'modified', 'unbind', 'action'];
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  constructor(private folderService: FolderService,
              private dialogService: DialogService,
              private dialog: MatDialog,
              private iconRegistry: MatIconRegistry,
              private sanitizer: DomSanitizer,
              private translate: TranslateService) {
    iconRegistry.addSvgIcon('unbind', sanitizer.bypassSecurityTrustResourceUrl('assets/img/unbind.svg'));
  }

  ngOnInit(): void {
  }

  isRoot(folderId: string): boolean {
    return folderId === this.rootFolderId;
  }

  edit(element: FolderRetentionBinding): void {
    this.dialog.open(EditFolderPolicyDialogComponent, {
      data: {libraryDetails: this.libraryDetails, folder: element.folderId, isRoot: this.isRoot(element.folderId)},
      width: '500px'
    }).afterClosed().subscribe((choice) => {
      if (choice) {
        this.reloadSubject.next();
      }
    });
  }

  confirmUnbind(element: FolderRetentionBinding): void {
    const unbindMessage = this.translate.instant('dialog.unbind', { retention:  element.retentionId, folder: element.folderName });
    const dialogTitle = this.translate.instant('dialog.confirmation');
    this.dialogService.plain(unbindMessage, dialogTitle)
      .afterClosed().subscribe((choice) => {
      if (choice) {
        this.unbind(element);
      }
    });
  }

  unbind(element: FolderRetentionBinding): void {
    this.loadingSubject.next(true);
    const unbindRetentionCommand: UnbindRetentionsCommand = {
      retentionsIds: [element.retentionId],
      existingBindingTimestamp: new Date().getTime().toString()
    };
    this.folderService.unbind(this.libraryId, element.folderId, unbindRetentionCommand).subscribe({
      next: () => {
        this.loadingSubject.next(false);
        this.reload.next(true);
      },
      error: (error) => {
        this.loadingSubject.next(false);
        this.dialogService.error(error?.error?.error?.message ?? error.message);
      }
    });
  }

}
