import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BindRetentionsCommand, Policy} from '../../models/retention.model';
import {BehaviorSubject, Subject} from 'rxjs';
import {FolderService} from '../../services/folder.service';
import {DialogService} from '../../services/dialog.service';
import {ChipsComponent} from '../chips/chips.component';
import {RetentionService} from '../../services/retention.service';
import {LibraryDetails} from '../../models/library.model';
import {DOMAIN_FEATURES_PARAM, FINRA_DOMAIN_FEATURE} from "../../constants/policy-assign-constants";

@Component({
  selector: 'app-edit-folder-policy',
  templateUrl: './edit-folder-policy.component.html',
  styleUrls: ['./edit-folder-policy.component.scss']
})
export class EditFolderPolicyComponent implements OnInit, AfterViewInit, OnDestroy {

  public preSelectedPolicies: Policy[];
  public activePolicies: Policy[];
  @Input() folder: string;
  @Input() isRoot: boolean;
  @Input() libraryDetails: LibraryDetails;
  @Input() saveSubject: Subject<boolean>;
  public policiesInSelectedFolder: Policy[] = [];
  public selectedPolicyFromChipSubject = new Subject<Policy[]>();
  public clearPolicyChipsSubject = new Subject<void>();
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public isSelectedPoliciesNotEmpty = false;
  @ViewChild(ChipsComponent) chips: ChipsComponent;

  constructor(private folderService: FolderService,
              private dialogService: DialogService,
              private retentionService: RetentionService) {
  }

  ngOnInit(): void {
    this.getActivePolicyList();
    this.initSelectedPolicySubscription();
  }

  ngOnDestroy(): void {
    this.selectedPolicyFromChipSubject.unsubscribe();
  }

  public setPreSelectedPolicies(selectedArray?: Policy[]): void {
    if (selectedArray) {
      if ((selectedArray.filter((item) => !item.metadataBased).length > 0
        || this.policiesInSelectedFolder.filter((item) => !item.metadataBased).length > 0) && this.isRoot) {
        this.preSelectedPolicies = [...this.activePolicies.filter(item => item.metadataBased)];
        return;
      }
      if (selectedArray.length > 0 && !this.isRoot) {
        this.preSelectedPolicies = [];
        return;
      }
    }
    this.preSelectedPolicies = this.isRoot ? [...this.activePolicies] : [...this.activePolicies.filter(item => !item.metadataBased)];
  }

  getActivePolicyList(): void {
    let finraFlagHttpPram = this.getLibraryAssignedPolicyTypes();
    (finraFlagHttpPram == null ? this.retentionService.active() : this.retentionService.activeFinraFiltered(finraFlagHttpPram))
   .subscribe({
      next: (resp) => {
        this.activePolicies = resp.items;
        this.getPolicyInSelectedFolder();
        this.setPreSelectedPolicies(this.policiesInSelectedFolder);
      },
      error: error => this.dialogService.error(error?.error?.error?.message ?? error.message)
    });
  }

  private initSelectedPolicySubscription(): void {
    this.selectedPolicyFromChipSubject.subscribe((selectedList) => {
      this.setIsSelectedPoliciesEmpty();
      this.setPreSelectedPolicies(selectedList);
    });
  }

  public setIsSelectedPoliciesEmpty(): void {
    this.isSelectedPoliciesNotEmpty = this.chips ? this.chips.newlyAddedItems.length > 0 : false;
  }


  public getPolicyInSelectedFolder(): void {
    if (this.getAssignedPolicyIds().length > 0) {
      const array: Policy[] = [];
      const policiesInSelectedFolderIds = this.libraryDetails.retentionBindings
        .filter(binding => binding.folderId === this.folder)
        .map(id => ({id: id.retentionId}));
      this.activePolicies.forEach(active => {
        policiesInSelectedFolderIds.forEach(selected => {
          if (active.id === selected.id) {
            array.push(active);
          }
        });
      });
      this.policiesInSelectedFolder =  [...array];
    }
  }


  private getAssignedPolicyIds(): string[] {
    return this.libraryDetails.retentionBindings ? this.libraryDetails.retentionBindings.map((item) => item.retentionId) : [];
  }

  public bind(): void {
    this.loadingSubject.next(true);
    const selectedPolicies: string[] = this.chips.newlyAddedItems.map((item) => item.id);
    const bindRetentionCommand: BindRetentionsCommand = {
      retentionsId: selectedPolicies,
      existingBindingTimestamp: null
    };
    this.folderService.bind(this.libraryDetails.libraryId, this.folder, bindRetentionCommand).subscribe({
      next: () => {
        this.loadingSubject.next(false);
        this.saveSubject.next(false);
      },
      error: (error) => {
        this.loadingSubject.next(false);
        this.dialogService.error(error?.error?.error?.message ?? error.message);
      }
    });
  }

  ngAfterViewInit(): void {
    this.saveSubject.subscribe((value) => {
      if (value) {
        this.bind();
      }
    });
  }

  private getLibraryAssignedPolicyTypes(): Map<string, string> {
    if (this.libraryDetails.retentionBindings == null || this.libraryDetails.retentionBindings.length == 0) {
      return null;
    }
    const assignedFinraPolicy: number = this.libraryDetails.retentionBindings.filter(item => item.finraRetentionPolicy).length;
    return !!assignedFinraPolicy ? new Map().set(DOMAIN_FEATURES_PARAM, FINRA_DOMAIN_FEATURE) : new Map();
  }

}
