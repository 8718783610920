import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {ApiVersion} from '../constants/apis';
import {FinraSettings} from '../models/admin.model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AdminService extends AbstractService {

  constructor(private http: HttpClient) {
    super();
  }

  getFinraSettings(): Observable<FinraSettings> {
    const url = this.toUrl('admin', 'getFinraSettings', ApiVersion.V1);
    return this.http.get<FinraSettings>(url);
  }

  updateFinraSettings(finraSettings: FinraSettings): Observable<FinraSettings> {
    const url = this.toUrl('admin', 'updateFinraSettings', ApiVersion.V1);
    return this.http.post<FinraSettings>(url, finraSettings);
  }

  configureFinraLibrary(libraryId: string): Observable<FinraSettings> {
    const url = this.toUrl('admin', 'finra/configure/' + libraryId, ApiVersion.V1);
    return this.http.post<FinraSettings>(url, null);
  }
}
