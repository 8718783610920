import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AuditLogsListComponent} from './logs/list/audit-logs-list.component';
import {AuditLogsViewerComponent} from './logs/viewer/audit-logs-viewer.component';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTableModule} from '@angular/material/table';
import {MatIconModule} from '@angular/material/icon';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {FlexModule} from '@angular/flex-layout';
import {AuditRoutingModule} from './audit-routing.module';
import {MatTooltipModule} from '@angular/material/tooltip';



@NgModule({
  declarations: [AuditLogsListComponent, AuditLogsViewerComponent],
    imports: [
        CommonModule,
        AuditRoutingModule,
        MatCardModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        TranslateModule,
        MatDatepickerModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatIconModule,
        MatPaginatorModule,
        MatDialogModule,
        MatButtonModule,
        FlexModule,
        MatTooltipModule,
      TranslateModule.forChild({
        extend: true
      })
    ]
})
export class AuditModule { }
