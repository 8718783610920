import {Component, OnInit} from '@angular/core';
import {ReportingService} from '../../services/reporting.service';
import {DialogService} from '../../services/dialog.service';

@Component({
  selector: 'app-alert-quarter',
  templateUrl: './alert-quarter.component.html',
  styleUrls: ['./alert-quarter.component.scss']
})
export class AlertQuarterComponent implements OnInit {

  public reportDownloadUrl: string;

  constructor(private reportService: ReportingService, private dialogService: DialogService) {
  }

  ngOnInit(): void {
    this.reportService.loadWarnNotificationReportDetails().subscribe({
      next: resp => this.reportDownloadUrl = resp.reportGcsPath,
      error: error => this.dialogService.error(error?.error?.error?.message ?? error.message)
    });
  }

}
