import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LockManagementComponent} from './lock-management/lock-management.component';
import {LegalHoldListComponent} from './legal-hold/list/legal-hold-list.component';


const routes: Routes = [
  {
    path: 'lock-management',
    component: LockManagementComponent
  },
  {
    path: 'legal-holds',
    component: LegalHoldListComponent
  },
  {
    path: '', redirectTo: '/holds/lock-management', pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HoldsRoutingModule {
}
