import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {Library} from './library.model';
import {BehaviorSubject, Observable} from 'rxjs';
import {LibraryService} from '../services/library.service';
import {DEFAULT_LIBRARIES_ITEMS_PAGE_NUMBER} from '../constants/library-constants';
import {DialogService} from '../services/dialog.service';

export class LibrariesDataSource implements DataSource<Library> {

  private librariesSubject = new BehaviorSubject<Library[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private nextPageToken: string;
  public loading$ = this.loadingSubject.asObservable();
  public libraries: Library[] = [];

  constructor(private libService: LibraryService, private dialogService: DialogService) {
  }

  connect(collectionViewer: CollectionViewer): Observable<Library[]> {
    return this.librariesSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.librariesSubject.complete();
    this.loadingSubject.complete();
  }

  loadLibraries(filter = '', query = '', pageIndex = 0, pageSize = DEFAULT_LIBRARIES_ITEMS_PAGE_NUMBER): void {
    this.loadingSubject.next(true);
    this.libService.list(filter, query, this.nextPageToken, pageSize).subscribe({
      next: (resp) => {
        if (!resp.libraries) {
          resp.libraries = [];
        }
        this.librariesSubject.next(resp.libraries);
        this.nextPageToken = resp.nextPageToken;
        this.loadingSubject.next(false);
        this.libraries.push(...resp.libraries);
      },
      error: (error) => {
        this.loadingSubject.next(false);
        this.dialogService.error(error?.error?.error?.message ?? error.message);
      }
    });
  }

  public loadLibrariesPage(eventPageIndex: number,
                           libraryListFilter: string,
                           query = '',
                           pageIndex = 0,
                           pageSize = DEFAULT_LIBRARIES_ITEMS_PAGE_NUMBER): void {

    const start = eventPageIndex * pageSize;
    const end = (eventPageIndex + 1) * pageSize;

    if (start >= this.libraries.length) {
      this.loadLibraries(libraryListFilter, query, pageIndex, pageSize);
    } else {
      this.librariesSubject.next(this.libraries.slice(start, end));
    }
  }

  init(libraryListFilter: string, query: string, pageIndex: number, pageSize: number): void {
    this.nextPageToken = null;
    this.libraries = [];
    this.loadLibraries(libraryListFilter, query, pageIndex, pageSize);
  }
}
