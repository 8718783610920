<button mat-icon-button class="close-button" [mat-dialog-close]="false">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h2 mat-dialog-title class="header">{{"legalHold.creator.title" | translate}}</h2>
<mat-dialog-content id="creation-dialog-content" class="mat-typography">
  <div class="spinner-container" *ngIf="loading$ | async">
    <mat-spinner></mat-spinner>
  </div>
  <section [hidden]="loading$ | async">
    <form [formGroup]="creationForm">
      <p class="grey-label label-margin">{{"legalHold.creator.text.typeNumber"|translate}}</p>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-form-field appearance="fill" fxFlex="48">
          <mat-label>{{"legalHold.creator.labels.type"|translate}}</mat-label>
          <input matInput formControlName="type" required autocomplete="off">
        </mat-form-field>
        <mat-form-field appearance="fill" fxFlex="48">
          <mat-label>{{"legalHold.creator.labels.number"|translate}}</mat-label>
          <input matInput formControlName="number" type="number" min="1" required autocomplete="off">
        </mat-form-field>
      </div>
      <p class="grey-label label-margin">{{"legalHold.creator.text.rules"|translate}}
        <button mat-icon-button aria-label="Add metadata" type="button" (click)="addRule()">
          <mat-icon>add</mat-icon>
        </button>
      </p>
      <div fxLayout="column" fxLayoutAlign="space-between start"  class="rules-container">
        <ng-template #viewContainerRef></ng-template>
      </div>
      <section mat-dialog-actions align="end" class="creator-footer">
        <section fxFlex fxLayout="row" fxLayoutAlign="end center">
          <button mat-button [mat-dialog-close]="false">
            {{"legalHold.creator.cancel"| translate}}
          </button>
          <button mat-button class="create-btn" [disabled]="!creationForm.valid || !isValid()" (click)="create()">
            {{"legalHold.creator.create"|translate}}</button>
        </section>
      </section>
    </form>
  </section>
</mat-dialog-content>
