<section class="main-content">
  <h1>
     <span (click)="gotoPath()">{{'title.deleted' | translate}}</span>
    <!--span (click)="gotoPath()">CIAO CIAO </span-->
    >
    {{deletionBatchId}}
  </h1>

  <mat-card class="unlocked-document-card no-padding" >

    <p class="grey-label padding-section">
      {{'report.deletedDocuments.details.subtitle' | translate}}
    </p>

    <div class="spinner-container" *ngIf="loading$ | async">
      <mat-spinner></mat-spinner>
    </div>

    <section  [hidden]="loading$ | async">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <!-- deletionBatchId -->
        <ng-container matColumnDef="libraryId">
          <mat-header-cell *matHeaderCellDef>{{'report.deletedDocuments.details.libraryId' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{getLibraryIdFromPurgeLibraryJob(element)}} </mat-cell>
        </ng-container>

        <!-- executionDate -->
        <ng-container matColumnDef="executionDate">
          <mat-header-cell *matHeaderCellDef>{{'report.deletedDocuments.details.executionDate' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.createdAtTimestamp | date:'medium'}} </mat-cell>
        </ng-container>

        <!-- executionDate -->
        <ng-container matColumnDef="deletedDocs">
          <mat-header-cell *matHeaderCellDef>{{'report.deletedDocuments.details.deletedDocs' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.numberOfDocumentsDeleted}} </mat-cell>
        </ng-container>

        <!-- executionDate -->
        <ng-container matColumnDef="errors">
          <mat-header-cell *matHeaderCellDef>{{'report.deletedDocuments.details.errors' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.numberOfDocumentsToDelete - element.numberOfDocumentsDeleted}} </mat-cell>
        </ng-container>

        <!-- export -->
        <ng-container matColumnDef="export">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button mat-button class="btn-background-blue fw-500" (click)="exportDeletedDocsForLibraryJob(element)">
              {{'report.deletedDocuments.details.exportButtonLabel' | translate}}
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns()"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns();"></mat-row>
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">{{"dataNotFound" | translate}}</td>
        </tr>
      </table>
    </section>
    <section [hidden]="loading$ | async">
      <mat-paginator [pageSizeOptions]="[10,20,30]"></mat-paginator>
    </section>

  </mat-card>
</section>
