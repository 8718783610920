<button mat-icon-button class="close-button" [mat-dialog-close]="false">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h2 mat-dialog-title class="header">{{'menu.policy' | translate}}:  {{policy.id}}</h2>
<div mat-dialog-content>
  <div fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="column-container margin-bottom-container">
      <div fxLayout="column" fxFlex="48">
        <mat-label class="grey-label">{{"policies.creator.steps.identification.labels.function"|translate}}</mat-label>
        <p>{{policy.function}}</p>
      </div>
      <div fxLayout="column" fxFlex="48">
        <mat-label
          class="grey-label">{{"policies.creator.steps.identification.labels.sub_function"|translate}}</mat-label>
        <p>{{policy.subFunction}}</p>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="column" fxFlex="48">
        <mat-label class="grey-label">{{"policies.creator.steps.identification.labels.category"|translate}}</mat-label>
        <p>{{policy.recordCategory}}</p>
      </div>
      <div fxLayout="column" fxFlex="48">
        <mat-label
          class="grey-label">{{"policies.creator.steps.identification.labels.description"|translate}}</mat-label>
        <p>{{policy.description}}</p>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="column-container margin-bottom-container">
      <div fxLayout="column" fxFlex="48">
        <mat-label class="grey-label">{{"policies.creator.steps.setup.labels.event"|translate}}</mat-label>
        <p>{{policy.event}}</p>
      </div>
      <div fxLayout="row" fxFlex="48">
      <div fxLayout="column" fxFlex="50">
        <mat-label class="grey-label">{{"policies.creator.steps.setup.labels.offset"|translate}}</mat-label>
        <p >{{policy.customOffset}} <span> {{"policies.creator.steps.setup.labels.years"|translate}}</span></p>
      </div>
      <div fxLayout="column" fxFlex="50">
        <mat-label class="grey-label">{{"policies.creator.steps.setup.labels.time"|translate}}</mat-label>
        <p>{{policy.retentionTime}}<span> {{"policies.creator.steps.setup.labels.years"|translate}}</span></p>
      </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center" [ngStyle]="{'margin-bottom':isSuperAdmin ? '0' : '30px' }">
      <div fxLayout="column">
        <mat-label class="grey-label">{{'policies.list.table.metadataBased'|translate}}</mat-label>
        <section *ngIf="policy.metadataBased">
          <ng-container *ngFor="let metadata of metadataArray">
            <p class="metadata-text">{{metadata.property}}: {{metadata.value}}</p>
          </ng-container>
        </section>
        <p *ngIf="!policy.metadataBased">No</p>
      </div>
    </div>
    <div class="actions" fxLayout="row" fxLayoutAlign="end center" *ngIf="isSuperAdmin">
      <button mat-button mat-dialog-close  [mat-dialog-close]="policy">{{'policies.editor.edit'|translate}}</button>
    </div>
  </div>
</div>

