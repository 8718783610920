import {Component, NgZone, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AdminService} from '../../../services/admin.service';
import {FinraSettings} from '../../../models/admin.model';
import {DialogService} from '../../../services/dialog.service';
import {BehaviorSubject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../../services/user.service';
import {LibraryService} from '../../../services/library.service';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {LibraryTagFilter} from '../../../constants/library-constants';
import {DEFAULT_LIBRARIES_ITEMS} from '../../../constants/reporting-constants';
import {Library} from '../../../models/library.model';
import {CustomSnackBarService} from '../../../services/custom-snackbar.service';

@Component({
  selector: 'app-settings-management',
  templateUrl: './settings-management.component.html',
  styleUrls: ['./settings-management.component.scss']
})
export class SettingsManagementComponent implements OnInit {

  public settingsFormGroup: FormGroup;

  public bucketName = new FormControl();
  public driveLabelId = new FormControl();
  public driveLabelFinalizedStatusFieldId = new FormControl();
  public driveLabelFinalizedStatusFieldValueId = new FormControl();
  public driveLabelFinalizedAndExportedStatusFieldValueId = new FormControl();
  public referenceCatalogId = new FormControl();
  public applyConfigurationButtonDisabled: boolean = true;

  public applySettingsFormGroup = this.formBuilder.group({
    library: [null]
  });

  public libraries: Library[] = [];
  public finraSettings: FinraSettings;

  public loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  constructor(private formBuilder: FormBuilder,
              private adminService: AdminService,
              private ngZone: NgZone,
              private dialogService: DialogService,
              private userService: UserService,
              private translate: TranslateService,
              private libraryService: LibraryService,
              private customSnackBarService: CustomSnackBarService) {
  }

  ngOnInit(): void {
    this.settingsFormGroup = this.formBuilder.group({
      bucketName: [this.bucketName, Validators.required],
      driveLabelId: [this.driveLabelId, Validators.required],
      driveLabelFinalizedStatusFieldId: [this.driveLabelFinalizedStatusFieldId, Validators.required],
      driveLabelFinalizedStatusFieldValueId: [this.driveLabelFinalizedStatusFieldValueId, Validators.required],
      driveLabelFinalizedAndExportedStatusFieldValueId: [this.driveLabelFinalizedAndExportedStatusFieldValueId, Validators.required],
      referenceCatalogId: [this.referenceCatalogId, Validators.required]
    });

    this.getDomainConfiguration();

    this.applySettingsFormGroup.get('library')?.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe(searchTextValue => {
        if (searchTextValue) {
          if (typeof searchTextValue === 'string') {
            this.applyConfigurationButtonDisabled = true;

            if (searchTextValue.trim().length >= 3) {
              this.loadLibraries(searchTextValue);
              this.applyConfigurationButtonDisabled = true;
            }

          } else {
            this.applyConfigurationButtonDisabled = false;
          }

        } else {
          this.applyConfigurationButtonDisabled = true;
        }
      });
  }

  getDomainConfiguration(): void {
    this.loadingSubject.next(true);
    this.adminService.getFinraSettings().subscribe({
      next: (resp) => {
        this.finraSettings = resp;
        if (this.finraSettings.allFieldAreSet) {
          this.initializeAndDisableSettingsFormGroup();
          if (!this.isSuperAdmin()) {
            this.applySettingsFormGroup.get('library').disable();
          }
        } else {
          if (this.isSuperAdmin()) {
            this.initializeSettingsFormGroup();
            this.applySettingsFormGroup.get('library').disable();
          } else {
            this.initializeAndDisableSettingsFormGroup();
            this.applySettingsFormGroup.get('library').disable();
          }
        }
        this.loadingSubject.next(false);
      }, error: (error) => {
        this.loadingSubject.next(false);
        this.dialogService.error(error?.error?.error?.message ?? error.message);
      }
    });
  }

  applyConfigurationCard(): boolean {
    return this.finraSettings != null && this.finraSettings.allFieldAreSet;
  }

  initializeSettingsFormGroup(): void {
    this.settingsFormGroup.get('bucketName').setValue(this.finraSettings.temporaryBucket);
    this.settingsFormGroup.get('referenceCatalogId').setValue(this.finraSettings.referenceCatalogId);
    this.settingsFormGroup.get('driveLabelId').setValue(this.finraSettings.googleDriveLabel);
    this.settingsFormGroup.get('driveLabelFinalizedStatusFieldId').setValue(this.finraSettings.driveLabelFinalizedStatusFieldId);
    this.settingsFormGroup.get('driveLabelFinalizedStatusFieldValueId').setValue(this.finraSettings.driveLabelFinalizedStatusFieldValueId);
    this.settingsFormGroup.get('driveLabelFinalizedAndExportedStatusFieldValueId').setValue(this.finraSettings.driveLabelFinalizedAndExportedStatusFieldValueId);
  }

  initializeAndDisableSettingsFormGroup(): void {
    this.initializeSettingsFormGroup();
    this.settingsFormGroup.get('bucketName').disable();
    this.settingsFormGroup.get('referenceCatalogId').disable();
    this.settingsFormGroup.get('driveLabelId').disable();
    this.settingsFormGroup.get('driveLabelFinalizedStatusFieldId').disable();
    this.settingsFormGroup.get('driveLabelFinalizedStatusFieldValueId').disable();
    this.settingsFormGroup.get('driveLabelFinalizedAndExportedStatusFieldValueId').disable();
  }

  save(): void {
    this.loadingSubject.next(true);
    this.adminService.updateFinraSettings(this.createFinraSettingsToSave(this.settingsFormGroup)).subscribe({
      next: (resp) => {
        this.finraSettings = resp;
        this.initializeAndDisableSettingsFormGroup();
        this.applySettingsFormGroup.get('library').enable();
        this.dialogService.info(this.translate.instant('finraSettings.dialog.savedMessage'))
          .afterClosed().subscribe((choice) => {
          if (choice) {
            this.loadingSubject.next(false);
          }
        });
      }, error: (error) => {
        this.loadingSubject.next(false);
        this.dialogService.error(error?.error?.error?.message ?? error.message);
      }
    });
  }

  loadLibraries(query: string): void {

    this.libraryService.list(LibraryTagFilter.ALL, query, '', DEFAULT_LIBRARIES_ITEMS).subscribe({
      next: (resp) => this.libraries = resp.libraries
      , error: (error) => this.dialogService.error(error?.error?.error?.message ?? error.message)
    });
  }

  public applyFinraSettings(): void {
    const settingsToApply = this.applySettingsFormGroup.getRawValue();
    this.adminService.configureFinraLibrary(settingsToApply.library.libraryId).subscribe({
      next: () => {
        this.customSnackBarService.openSuccessSnackbar(this.translate.instant('snackBarMessage.applyFinraSettingSnackbarMessage'));
        this.applySettingsFormGroup.reset();
        this.applyConfigurationButtonDisabled = true;
      }, error: (error) => {
        this.loadingSubject.next(false);
        this.dialogService.error(error?.error?.error?.message ?? error.message);
      }
    });
  }

  displayFn(library: Library): string {
    return library && library.displayName ? library.displayName : '';
  }

  isSuperAdmin(): boolean {
    return this.userService.isSuperAdmin();
  }

  private createFinraSettingsToSave(settingsFormGroup: FormGroup): FinraSettings {
    const settings = settingsFormGroup.getRawValue();
    return {
      temporaryBucket: settings.bucketName,
      referenceCatalogId: settings.referenceCatalogId,
      googleDriveLabel: settings.driveLabelId,
      driveLabelFinalizedStatusFieldId: settings.driveLabelFinalizedStatusFieldId,
      driveLabelFinalizedStatusFieldValueId: settings.driveLabelFinalizedStatusFieldValueId,
      driveLabelFinalizedAndExportedStatusFieldValueId: settings.driveLabelFinalizedAndExportedStatusFieldValueId,
      allFieldAreSet: undefined
    };
  }
}
