import { NgModule } from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {UploadErrorsComponent} from './upload-errors/upload-errors.component';
import {MatCardModule} from '@angular/material/card';
import {TranslateModule} from '@ngx-translate/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTableModule} from '@angular/material/table';
import {MatIconModule} from '@angular/material/icon';
import {MatPaginatorModule} from '@angular/material/paginator';
import {UploadErrorsRouting} from './upload-errors-routing.module';
import {FlexModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';



@NgModule({
  declarations: [UploadErrorsComponent],
  imports: [
    CommonModule,
    UploadErrorsRouting,
    MatCardModule,
    TranslateModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    FlexModule,
    MatButtonModule,
    MatTooltipModule,
    TranslateModule.forChild({
      extend: true
    })
  ],
  providers: [
  DatePipe
]
})
export class UploadErrorsModule { }
