import {Component, OnInit} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators
} from '@angular/forms';
import {DialogService} from '../../../services/dialog.service';
import {LibraryService} from '../../../services/library.service';
import {Library} from '../../../models/library.model';
import {LibraryTagFilter} from '../../../constants/library-constants';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {DEFAULT_LIBRARIES_ITEMS} from '../../../constants/reporting-constants';
import {ReportingService} from '../../../services/reporting.service';
import {Category} from '../../../models/reporting.model';
import {CustomSnackBarService} from '../../../services/custom-snackbar.service';
import {DateTime} from 'luxon';
import {TranslateService} from '@ngx-translate/core';
import {DateAdapter} from '@angular/material/core';
import {endDateStartDateValidator} from '../../../validators/custom-validators';


@Component({
  selector: 'app-reports-deleted-docs',
  templateUrl: './reports-to-be-deleted-docs.component.html',
  styleUrls: ['./reports-to-be-deleted-docs.component.scss']
})
export class ReportsToBeDeletedDocsComponent implements OnInit {

  public docToBeDeletedReportForm: FormGroup;

  public startDateControl = new FormControl();
  public endDateControl = new FormControl();
  public librarySelectorControl = new FormControl();
  public categorySelectorControl = new FormControl();

  public libraries: Library[] = [];
  public categories: Category[] = [];
  public exportButtonDisabled = false;

  constructor(private libraryService: LibraryService,
              private reportingService: ReportingService,
              private dialogService: DialogService,
              private customSnackBarService: CustomSnackBarService,
              private formBuilder: FormBuilder,
              private translate: TranslateService,
              private dateAdapter: DateAdapter<any>) {

    this.loadLibraries('');
    this.categorySelectorControl.disable();

    this.docToBeDeletedReportForm = formBuilder.group({
        startDate: [this.startDateControl, Validators.required],
        endDate: [this.endDateControl, Validators.required],
        library: this.librarySelectorControl,
        category: this.categorySelectorControl
      }, {validator: [endDateStartDateValidator('startDate', 'endDate')]}
    );

    // Mat DatePicker internationalization
    this.dateAdapter.setLocale(navigator.language);
  }

  ngOnInit(): void {
    this.librarySelectorControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged()).subscribe((value) => {

      this.exportButtonDisabled = this.isValueString(value) && value !== '';
      this.categorySelectorControl.disable();
      this.categorySelectorControl.reset();

      if (!!value && (value.length === 0 || value.length >= 3)) {
        this.loadLibraries(value);
      }
    });
  }

  private isValueString(value: any): any {
    return typeof value === 'string' || value instanceof String;
  }

  export(formDirective: FormGroupDirective): void {
    const rawValueForm = this.docToBeDeletedReportForm.getRawValue();

    this.reportingService.exportDocsToBeDeleted(
      this.displayDateFormDatePicker(rawValueForm.startDate),
      this.displayDateFormDatePicker(rawValueForm.endDate),
      rawValueForm.category !== null ? rawValueForm.category.id : null,
      rawValueForm.library !== null ? rawValueForm.library.libraryId : null)
      .subscribe({
        next: () => {
          this.customSnackBarService.openSuccessSnackbar(this.translate.instant('snackBarMessage.exportStarted'));
          formDirective.resetForm();
          this.docToBeDeletedReportForm.reset();
          this.exportButtonDisabled = true;
        },
        error: error => this.dialogService.error(error?.error?.error?.message ?? error.message)
      });
  }

  displayDateFormDatePicker(date: any): string {
    return DateTime.fromJSDate(date).toISODate();
  }

  displayFn(library: Library): string {
    return library && library.displayName ? library.displayName : '';
  }

  librarySelected(): void {
    this.categorySelectorControl.reset();
    this.exportButtonDisabled = true;
    const libraryId = this.librarySelectorControl.value.libraryId as string;
    if (libraryId !== undefined) {
      this.loadCategories(libraryId);
    }
  }

  loadLibraries(query: string): void {
    this.libraryService.list(LibraryTagFilter.ACTIVE, query, '', DEFAULT_LIBRARIES_ITEMS)
      .subscribe({
        next: resp => this.libraries = resp.libraries,
        error: error => this.dialogService.error(error?.error?.error?.message ?? error.message)
      });
  }

  loadCategories(libraryId: string): void {
    this.reportingService.listRecordCategories(libraryId).subscribe({
      next: (resp) => {
        this.categories = resp.items;
        if (this.categories.length > 0) {
          this.categorySelectorControl.enable();
        }
      },
      error: error => this.dialogService.error(error?.error?.error?.message ?? error.message)
    });
  }
}

