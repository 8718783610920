<section class="main-content">
  <h1>{{'title.tobedeleted' | translate}}</h1>
  <mat-card>

    <form [formGroup]="docToBeDeletedReportForm" #formDirective="ngForm" >

      <p class="grey-label">{{'report.toBeDeletedDocuments.subtitle1' | translate}}</p>
      <div fxLayout="row" fxLayoutAlign="start">
        <mat-form-field appearance="fill">
          <mat-label>{{'report.toBeDeletedDocuments.startDate' | translate}}</mat-label>
          <input matInput [matDatepicker]="pickerStart" formControlName="startDate" autocomplete="off">
          <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
          <mat-datepicker #pickerStart></mat-datepicker>
        </mat-form-field>

        <div class="left-margin top-margin arrow-color">
          <mat-icon>arrow_forward</mat-icon>
        </div>

        <mat-form-field appearance="fill" class="left-margin">
          <mat-label>{{'report.toBeDeletedDocuments.endDate' | translate}}</mat-label>
          <input matInput [matDatepicker]="pickerEnd" formControlName="endDate" autocomplete="off">
          <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
          <mat-datepicker #pickerEnd></mat-datepicker>
        </mat-form-field>
      </div>

      <p class="grey-label">{{'report.toBeDeletedDocuments.subtitle2' | translate}}</p>
      <div fxLayout="row" fxLayoutAlign="start">
        <mat-form-field class="example-full-width" appearance="fill" fxFlex="48">
          <mat-label>{{'report.toBeDeletedDocuments.librarySearchLabel' | translate}}</mat-label>
          <input type="text" matInput formControlName="library" [matAutocomplete]="auto">
          <mat-icon matSuffix>search</mat-icon>

          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="librarySelected()">
            <mat-option *ngFor="let library of libraries"
                        [value]="library">
              <div class="library-suggestion-item"fxLayout="row">
                <div fxFlex="45" class="code-id" > {{library.displayName}}</div>
                <div fxFlex="30" class="code-function">{{library.libraryId}}</div>
                <div fxFlex="30" class="code-subfunction">{{library.libraryType}}</div>
              </div>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field appearance="fill" fxFlex="48"  class="left-margin">
          <mat-label>{{'report.toBeDeletedDocuments.categorySearchLabel' | translate}}</mat-label>
          <mat-select formControlName="category">
            <mat-option></mat-option>
            <mat-option *ngFor="let category of categories" [value]="category" >
              {{category.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <br>

      <button mat-button class="btn-background-blue fw-500" (click)="export(formDirective)" [disabled]="!docToBeDeletedReportForm.valid || exportButtonDisabled">
        {{'report.toBeDeletedDocuments.exportButtonLabel' | translate}}
      </button>

    </form>

  </mat-card>
</section>
