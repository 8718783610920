import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ReportsDeletedDocsComponent} from './deleted-docs/reports-deleted-docs.component';
import {ReportsToBeDeletedDocsComponent} from './to-be-deleted-docs/reports-to-be-deleted-docs.component';
import {ReportsAssignmentComponent} from './assignment/reports-assignment.component';
import {ReportsDeletedDocsDetailsComponent} from './deleted-docs/deleted-docs-details/reports-deleted-docs-details.component';

const routes: Routes = [
  {
    path: 'assignment',
    component: ReportsAssignmentComponent
  },
  {
    path: 'deleted-documents',
    component: ReportsDeletedDocsComponent
  },
  {
    path: 'deleted-documents/:id',
    component: ReportsDeletedDocsDetailsComponent
  },
  {
    path: 'to-be-deleted-documents',
    component: ReportsToBeDeletedDocsComponent
  },
  {
    path: '',
    component: ReportsAssignmentComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule {
}
