import {Component} from '@angular/core';
import {LibraryService} from '../../../services/library.service';
import {LibraryTagFilter} from '../../../constants/library-constants';
import {LibrariesComponent} from '../libraries.component';
import {DialogService} from '../../../services/dialog.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-active-libraries',
  templateUrl: './../libraries.component.html',
  styleUrls: ['./../libraries.component.scss']
})
export class ActiveLibrariesComponent extends LibrariesComponent {

  constructor(libraryService: LibraryService, dialogService: DialogService, router: Router) {
    super(libraryService, dialogService, router);
    this.libraryListFilter = LibraryTagFilter.ACTIVE;
    this.libraryActiveTab = true;
    this.displayedColumns = ['displayName', 'libraryType', 'numberOfRetentionBindings', 'edit', 'link'];
    this.pageTitle = 'active';

  }
}
