import {ChangeDetectorRef} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {RetentionMethod} from '../../../constants/retention-constants';
import {BothOrNoneValidator, duplicateValidator, forbiddenNameValidator} from '../../../validators/custom-validators';
import {RetentionService} from '../../../services/retention.service';
import {DialogService} from '../../../services/dialog.service';
import {DateTime} from 'luxon';
import {AlertQuarterComponent} from '../../../components/alert-quarter/alert-quarter.component';
import {PoliciesBaseComponent} from '../policies-base-component';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../../services/user.service';
import {AdminService} from '../../../services/admin.service';
import {Router} from '@angular/router';

export abstract class PoliciesCreatorComponent extends PoliciesBaseComponent {

  public isActive = true;
  public isFinra = false;
  private policyCodeRegExp = '^[\\w-]+$';

  constructor(private dialog: MatDialog,
              private userService: UserService,
              private adminService: AdminService,
              private router: Router,
              dialogRef: MatDialogRef<PoliciesCreatorComponent>,
              formBuilder: FormBuilder,
              cdr: ChangeDetectorRef,
              retentionService: RetentionService,
              dialogService: DialogService,
              translate: TranslateService) {

    super(dialogRef, formBuilder, cdr, retentionService, dialogService, translate);
    dialogRef.disableClose = true;
  }

  initForms(): void {
    this.initIdentificationForm();
    this.initSetupForm();
    this.initOptionsForm();
  }

  initIdentificationForm(): void {
    this.identificationFormGroup = this.formBuilder.group({
      code: ['', {validators: [Validators.required, forbiddenNameValidator(new RegExp(this.policyCodeRegExp))]}],
      country: ['', {validators: [Validators.required, forbiddenNameValidator(new RegExp(this.policyCodeRegExp))]}],
      function: [''],
      subFunction: [''],
      category: [''],
      description: [''],
    });
  }

  initSetupForm(): void {
    this.setupFormGroup = this.formBuilder.group({
      method: [RetentionMethod.CREATION_DATE, Validators.required],
      time: [1, [Validators.required, Validators.min(-1)]],
      offset: [undefined, Validators.min(0)],
      event: [{value: RetentionMethod.CREATION_DATE, disabled: true}, Validators.required],
      secondaryLabel: [''],
    });
  }

  initOptionsForm(): void {
    this.optionsFormGroup = this.formBuilder.group({
      active: [this.isActive],
      finra: [this.isFinra],
      metadata: this.formBuilder.array([
        this.formBuilder.group({
          property: [''],
          value: ['']
        }, {validators: BothOrNoneValidator()})
      ], duplicateValidator())
    });
  }

  create(): void {
    if (this.retentionService.isInQuarterInterval(DateTime.local())) {
      this.dialog.open(AlertQuarterComponent).afterClosed().subscribe((choice) => {
        if (choice) {
          this.confirmCreate();
        }
      });
    } else {
      this.confirmCreate();
    }
  }

  getActiveText(): string {
    return this.isActive ? 'activated' : 'deactivated';
  }
}
