import {Component, Input, OnInit} from '@angular/core';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-library-calculate-message',
  templateUrl: './library-calculate-message.component.html',
  styleUrls: ['./library-calculate-message.component.scss']
})
export class LibraryCalculateMessageComponent implements OnInit {

  @Input() recalculateSubject: Subject<boolean>;

  constructor() {
  }

  ngOnInit(): void {
  }

  recalculate(): void {
    this.recalculateSubject.next(true);
  }

}
