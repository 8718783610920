<header>
  <h1 mat-dialog-title>{{'policies.csvImportReport.title' | translate}}</h1>
</header>

<div mat-dialog-content>

  <div fxLayout="row">
    <div class="report-element">
      <label class="report-detail">{{'policies.csvImportReport.reportDetails.totalLines' | translate}}</label>
      <div>{{this.data.countTotalLines}}</div>
    </div>
    <div class="report-element">
      <label class="report-detail">{{'policies.csvImportReport.reportDetails.totalInvalidLines' | translate}}</label>
      <div>{{this.data.countInvalidLines}}</div>
    </div>
    <div class="report-element">
      <label class="report-detail">{{'policies.csvImportReport.reportDetails.totalNewLines' | translate}}</label>
      <div>{{this.data.countImportedLines}}</div>
    </div>
    <div class="report-element">
      <label class="report-detail">{{'policies.csvImportReport.reportDetails.totalUnchangedLines' | translate}}</label>
      <div>{{this.data.countUnchangedLines}}</div>
    </div>
    <div class="report-element">
      <label class="report-detail">{{'policies.csvImportReport.reportDetails.totalUpdatedLines' | translate}}</label>
      <div>{{this.data.countUpdatedLines}}</div>
    </div>
  </div>

  <div *ngIf="data.invalidLinesMap">

    <label class="report-detail">{{'policies.csvImportReport.errorDetails.title' | translate}}</label>

    <div *ngFor="let line of data.invalidLinesMap | keyvalue" class="report-invalid-line">
      {{line.key}}: {{line.value}}
    </div>

    <div class="report-note">
      {{'policies.csvImportReport.errorDetails.note' | translate}}
    </div>
  </div>

</div>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button mat-dialog-close>Close</button>
</div>
