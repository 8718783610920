<button mat-icon-button class="close-button" [mat-dialog-close]="false">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h2 mat-dialog-title class="header">{{'libraries.edit.assigner.title'|translate}}</h2>
<mat-dialog-content id="schedule-assigner-dialog-content" class="mat-typography">
  <div class="spinner-container" *ngIf="loading$ | async">
    <mat-spinner></mat-spinner>
  </div>
  <section [hidden]="loading$ | async">
    <mat-horizontal-stepper id="schedule-assigner-stepper" labelPosition="bottom" linear #stepper>
      <mat-step>
        <ng-template matStepLabel>{{'libraries.edit.assigner.step1.title'|translate}}</ng-template>
        <section fxLayout="column" fxLayoutAlign="start start">
          <mat-label class="grey-label label">{{'libraries.edit.assigner.step1.label'|translate}}</mat-label>
          <section class="folder-picker-container" *ngIf="activePolicies">
            <app-folder-picker [selectedSubject]="selectedFolderSubject"
                               [rootFolderId]="data.libraryDetails.rootFolderId"></app-folder-picker>
          </section>
        </section>
        <section mat-dialog-actions align="end">
          <section fxFlex fxLayout="row" fxLayoutAlign="end center">
            <button mat-button class="blue-label fw-500"
                    [disabled]="!activePolicies || policiesInSelectedFolder.length > 0 && !isRoot()" matStepperNext>
              {{"policies.creator.next"|translate}}
            </button>
          </section>
        </section>
      </mat-step>
      <mat-step [stepControl]="policiesFormGroup">
        <form [formGroup]="policiesFormGroup">
          <ng-template matStepLabel>{{'libraries.edit.assigner.step2.title'|translate}}</ng-template>
          <section class="top-margin-section">
            <mat-label class="grey-label">{{'libraries.edit.assigner.step2.folder'|translate}}</mat-label>
            <h3 class="path-style">{{path || data.libraryDetails.displayName}}</h3>
          </section>
          <section class="top-margin-section">
            <mat-label class="grey-label margin-bottom-label">{{'libraries.edit.assigner.step2.choose'|translate}}</mat-label>
            <app-chips [allItems]="preSelectedPolicies"
                       [selectedItems]="policiesInSelectedFolder"
                       [selectedEventSubject]="selectedPoliciesFromChipSubject"
                       [clearSubject]="clearPolicyChipsSubject"
                       [label]="'Retention ID'"></app-chips>
            <p class="illegal-policy-selection-text red" *ngIf="illegalPolicySelectionState()">You can either assign FINRA or Standard policies to a library</p>
          </section>
          <section mat-dialog-actions align="end">
            <section fxFlex fxLayout="row" fxLayoutAlign="end center">
              <button mat-button class="blue-label fw-500" (click)="bind()" [disabled]="!isSelectedPoliciesNotEmpty || illegalPolicySelectionState()">
                {{"policies.editor.save"|translate}}
              </button>
            </section>
          </section>
        </form>
      </mat-step>
    </mat-horizontal-stepper>
  </section>
</mat-dialog-content>
