import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PoliciesRoutingModule} from './policies-routing.module';
import {PoliciesListComponent} from './list/policies-list.component';
import {MatCardModule} from '@angular/material/card';
import {TranslateModule} from '@ngx-translate/core';
import {MatStepperModule} from '@angular/material/stepper';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
import {MatRadioModule} from '@angular/material/radio';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSortModule} from '@angular/material/sort';
import {SharedModule} from '../shared/shared.module';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {PoliciesViewerComponent} from './viewer/policies-viewer.component';
import {MatDividerModule} from '@angular/material/divider';
import {MatTabsModule} from '@angular/material/tabs';
import {StandardPoliciesCreatorComponent} from './creator/standard/standard-policies-creator.component';
import {FinraPoliciesCreatorComponent} from './creator/finra/finra-policies-creator.component';
import { FinraPoliciesEditorComponent } from './editor/finra/finra-policies-editor.component';
import { StandardPoliciesEditorComponent } from './editor/standard/standard-policies-editor.component';

@NgModule({
  declarations: [PoliciesListComponent,
    PoliciesViewerComponent,
    StandardPoliciesCreatorComponent,
    FinraPoliciesCreatorComponent,
    FinraPoliciesEditorComponent,
    StandardPoliciesEditorComponent],
  imports: [
    CommonModule,
    SharedModule,
    PoliciesRoutingModule,
    MatCardModule,
    TranslateModule,
    MatStepperModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    FlexModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    ExtendedModule,
    MatMenuModule,
    MatTooltipModule,
    MatDividerModule,
    MatTabsModule,
    TranslateModule.forChild({
      extend: true
    })
  ]
})
export class PoliciesModule {
}
