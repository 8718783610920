import {Injectable} from '@angular/core';
import {ApiVersion} from '../constants/apis';
import {AbstractService} from './abstract.service';
import {UploadErrorData} from '../models/upload-error-data.model';
import {BlobUploadInfo} from '../models/gcs-blob.model';
import {UserService} from './user.service';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UploadErrorCallback} from '../models/upload-error-callback.model';

@Injectable({
  providedIn: 'root'
})
export class UploadErrorsService extends AbstractService {

  SLASH = '/';
  OCTET_STREAM_CONTENT_TYPE = 'application/octet-stream';
  GCS_API = environment.gcsAPI;
  callbackData: UploadErrorCallback;

  constructor(private http: HttpClient, private userService: UserService) {
    super();
  }

  getCallbackData(): UploadErrorCallback {
    return this.callbackData;
  }

  setCallbackData(uploadErrorCallback: UploadErrorCallback): void {
    this.callbackData = uploadErrorCallback;
  }

  clearCallbackData(): void {
    this.callbackData = null;
  }

  list(): Observable<{ items: UploadErrorData[] }> {
    const url = this.toUrl('holds', 'uploadErrors', ApiVersion.V1);
    return this.http.get<{ items: UploadErrorData[] }>(url);
  }

  getUploadErrorBucket(): Observable<{ bucketName: string }> {
    const url = this.toUrl('holds', 'uploadErrorBucket', ApiVersion.V1);
    return this.http.get<{ bucketName: string }>(url);
  }

  copy(blobUploadInfo: BlobUploadInfo, uploadErrorData: UploadErrorData): Observable<void> {
    const params = {blobUploadInfo, uploadErrorData};
    const url = this.toUrl('holds', 'copy', ApiVersion.V1);
    return this.http.post<void>(url, params);
  }

  insertObject(bucket, event): Observable<any> {
    const fileData = event.target.files[0];
    const reader = new FileReader();
    reader.readAsBinaryString(fileData);
    const contentType = fileData.type || this.OCTET_STREAM_CONTENT_TYPE;
    const objectPrefix = this.constructObjectPrefix();
    const metadata = {
      name: objectPrefix + fileData.name,
      mimeType: contentType
    };

    const path = this.GCS_API.replace('{BUCKET}', bucket);
    const boundary = '-------314159265358979323846';
    const delimiter = '\r\n--' + boundary + '\r\n';
    const closeDelimiter = '\r\n--' + boundary + '--';
    const base64Data = btoa(reader.result as string);
    const multipartRequestBody =
      delimiter +
      'Content-Type: application/json\r\n\r\n' +
      JSON.stringify(metadata) +
      delimiter +
      'Content-Type: ' + contentType + '\r\n' +
      'Content-Transfer-Encoding: base64\r\n' +
      '\r\n' +
      base64Data +
      closeDelimiter;
    const headers = new HttpHeaders({'Content-Type': 'multipart/mixed; boundary="' + boundary + '"'});
    const params = new HttpParams().set('uploadType', 'multipart');
    return this.http.post(path, multipartRequestBody, {headers, params});

  }

  constructObjectPrefix(): string {
    return this.userService.user.domain + this.SLASH + this.userService.user.email + this.SLASH + new Date().getTime() + this.SLASH;
  }
}
