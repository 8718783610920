import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AuditLog} from '../../../../models/audit-log.model';

@Component({
  selector: 'app-audit-logs-viewer',
  templateUrl: './audit-logs-viewer.component.html',
  styleUrls: ['./audit-logs-viewer.component.scss']
})
export class AuditLogsViewerComponent implements OnInit {

  public auditLog: AuditLog;
  constructor(@Inject(MAT_DIALOG_DATA) public data: { auditLog: AuditLog }) {
    this.auditLog = data.auditLog;
  }

  ngOnInit(): void {
  }

  edit(): void {
  }
}
