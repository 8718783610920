import {Component, Input} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {UserService} from '../../../services/user.service';
import {environment} from '../../../../environments/environment';
import {Scope} from '../../../constants/scopes';
import {Router} from '@angular/router';
import {ErrorService} from '../../../services/error.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  @Input() profile: any;
  clientId = environment.clientId;
  scopes = [Scope.EMAIL, Scope.PROFILE, Scope.OPENID].join(' ');
  googleLoading = false;
  autoEnabled = true;

  constructor(private authService: AuthService,
              private userService: UserService,
              public router: Router,
              public errorService: ErrorService) {
    this.authService.authErrorMessage$.asObservable().subscribe((error) => this.errorService.errorMessage$.next(error));
  }

  logout(): void {
    this.autoEnabled = false;
    this.googleLoading = false;
    this.userService.user = null;
    this.authService.logout();
  }

  googleAuth(): void {
    setTimeout(() => {
      this.googleLoading = true;
    });
  }

  googleLoginSuccess(event: any): void {
    try {
      this.authService.signInWithGoogle(event.detail, this.scopes, null);
    } catch (e: any) {
      this.googleLoading = false;
      this.authService.authErrorMessage$.next(e.error);
    }
  }

  loginError(): void {
    this.googleLoading = false;
    this.authService.authErrorMessage$.next('Error: An error occurred during authentication');
  }
}
