export enum ReportType {
  WARN
}

export enum Status {
  RUNNING = 'RUNNING',
  FINISHED = 'FINISHED',
  ERROR = 'ERROR'
}

export enum CsvCreationStatus {
  NO_CSV_CREATION = 'NO_CSV_CREATION',
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  FINISHED = 'FINISHED',
  ERROR = 'ERROR'
}

export const DEFAULT_LIBRARIES_ITEMS = 10;
export const  RETENTION_BINDINGS_ALL_BINDINGS_SELECTOR = 'ALL';
