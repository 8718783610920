import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {AbstractService} from './abstract.service';
import {first, tap} from 'rxjs/operators';
import {OAUTH2_TOKEN, SCOPES, TOKEN_CREATION_TIME} from '../constants/local-storage-constants';
import {Callback} from '../models/callback.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends AbstractService {

  profile: any;
  profileSubject: Subject<any> = new Subject<any>();
  authErrorMessage$ = new Subject<string>();

  constructor(private http: HttpClient, private router: Router) {
    super();
  }

  logout(): void {
    this.setToken(null);
    this.setProfile(null, null);
    this.setScopes(null);
    this.router.navigate(['login']);
  }

  setToken(token: string): void {
    !!token ? localStorage.setItem(OAUTH2_TOKEN, token) : localStorage.removeItem(OAUTH2_TOKEN);
  }

  getToken(): string {
    return localStorage.getItem(OAUTH2_TOKEN);
  }

  setScopes(scopes: string): void {
    !!scopes ? localStorage.setItem(SCOPES, scopes) : localStorage.removeItem(SCOPES);
  }

  getScopes(): string[] {
    if (!localStorage.getItem(SCOPES)) {
      return null;
    }
    return localStorage.getItem(SCOPES).split(' ');
  }

  setProfile(profile, callback: Callback): void {
    this.profile = profile;
    this.profileSubject.next({profile, callback});
  }

  // tslint:disable-next-line:typedef
  async signInWithGoogle(authDetails: any, scopes: string, route: Callback) {
    this.setToken(authDetails.accessToken);
    this.setScopes(scopes);
    localStorage.setItem(TOKEN_CREATION_TIME, String(Math.floor(Date.now() / 1000)));
    this.retrieveGoogleUserInfo().pipe(
      tap((user) => (this.setProfile(user, route))),
      first()
    )
      .subscribe({
        error: async (e: HttpErrorResponse) => {
          this.setToken(null);
          this.authErrorMessage$.next(e.error);
          await this.router.navigate(['login']);
        },
      });
  }

  isLoggedIn(): boolean {
    return !!this.getToken();
  }

  retrieveGoogleUserInfo(): Observable<any> {
    return this.http.get<any>('https://openidconnect.googleapis.com/v1/userinfo');
  }
}
