<section class="main-content" *ngIf="details">
  <h1><span class="pointer" (click)="back()">{{'menu.libraries'|translate}}</span> > {{details.displayName}}</h1>
  <!--CONFIGURATION STATUS-->
  <mat-card *ngIf="globalLoading$ | async">
    <div class="spinner-container" >
      <mat-spinner></mat-spinner>
    </div>
  </mat-card>
  <mat-card [fxHide]="globalLoading$ | async">
    <section class="actions-container" fxLayout="row" fxLayoutAlign="space-between center">
      <h2>{{'libraries.edit.status.title'|translate}}</h2>
      <section fxLayout="row">
        <button mat-icon-button class="action-btn" (click)="recalculate()"
                matTooltip="{{'libraries.edit.status.calculate.tooltips.recalculate' | translate}}">
          <mat-icon>refresh</mat-icon>
        </button>
        <button mat-icon-button class="action-btn" (click)="gotoLibrarySettingsUrl()"
                matTooltip="{{'libraries.edit.status.calculate.tooltips.libraryLink' | translate}}">
          <mat-icon>settings</mat-icon>
        </button>
      </section>
    </section>
    <section>
      <div class="spinner-container" *ngIf="loading$ | async">
        <mat-spinner></mat-spinner>
      </div>
      <section *ngIf="isNotYetCalculated()" [hidden]="loading$ | async">
        <app-library-calculate-message [recalculateSubject]="recalculateSubject"></app-library-calculate-message>
      </section>
      <section *ngIf="isReady() || isAllCriteriaMet()" [hidden]="loading$ | async">
        <app-all-criteria-met></app-all-criteria-met>
      </section>
      <section *ngIf="isWithoutPolicyAtRoot()" [hidden]="loading$ | async">
        <app-no-policy-at-root></app-no-policy-at-root>
      </section>
      <section *ngIf="isError()" [hidden]="loading$ | async">
        <app-library-configuration-error [dataSource]="details.documentClassStates"
                                         [isNotReady]="!isReady()"
                                         [isWithError]="isWithErrors()"
                                         [libraryId]="details.libraryId">
        </app-library-configuration-error>
      </section>
    </section>
  </mat-card>

  <!--FOLDERS-->
  <mat-card [fxHide]="globalLoading$ | async">
    <section class="actions-container" fxLayout="row" fxLayoutAlign="space-between center">
      <h2>{{'libraries.edit.folders.title'|translate}}</h2>
      <section fxLayout="row" *ngIf="details.retentionBindings">
        <button mat-button class="blue upper" (click)="confirmUnbindAll()">
          {{'libraries.edit.folders.button.unbind'|translate}}
        </button>
        <button mat-button class="blue upper" (click)="recalculateRetentionProperties()" *ngIf="isSuperAdmin()">
          {{'libraries.edit.folders.button.recalculate'|translate}}
        </button>
        <button mat-button (click)="openPolicyAssigner()" class="btn-background-blue margin-left-assign-btn upper" [disabled]="isError()">
          <mat-icon>add</mat-icon>
          {{'libraries.edit.folders.button.assign'|translate}}
        </button>
      </section>
    </section>
    <section *ngIf="details.retentionBindings" class="retention-table-container">
      <app-assigned-retentions-table [reloadSubject]="reloadAssignedRetentionsTableSubject"
                                     [libraryDetails]="details"
                                     [dataSource]="details.retentionBindings"
                                     [libraryId]="details.libraryId"
                                     [rootFolderId]="details.rootFolderId"
                                     [reload]="reloadAssignedRetentionsTableSubject"
                                     [isError]="isError()"></app-assigned-retentions-table>
    </section>
    <section *ngIf="!details.retentionBindings">
      <app-no-policy-assigned [isError]="isError()"
                                [openAssignerSubject]="openAssignerSubject"></app-no-policy-assigned>
    </section>
  </mat-card>
</section>
