import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {DialogService} from '../../../services/dialog.service';
import {CustomSnackBarService} from '../../../services/custom-snackbar.service';
import {BehaviorSubject} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {PurgeDomainJob} from '../../../models/reporting.model';
import {LibraryService} from '../../../services/library.service';
import {ReportingService} from '../../../services/reporting.service';
import {MatPaginator} from '@angular/material/paginator';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-reports-deleted-docs',
  templateUrl: './reports-deleted-docs.component.html',
  styleUrls: ['./reports-deleted-docs.component.scss']
})
export class ReportsDeletedDocsComponent implements OnInit, AfterViewInit {

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public dataSource: MatTableDataSource<PurgeDomainJob>;
  public columns = ['deletionBatchId', 'executionDate', 'view', 'export'];
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private libraryService: LibraryService,
              private reportingService: ReportingService,
              private dialogService: DialogService,
              private customSnackBarService: CustomSnackBarService,
              protected router: Router,
              private translate: TranslateService,
              private sanitizer: DomSanitizer,
              private iconRegistry: MatIconRegistry) {

    iconRegistry.addSvgIcon('view_data', sanitizer.bypassSecurityTrustResourceUrl('assets/img/view_data.svg'));
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    this.listPurgeDomainJob();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  displayedColumns(): string[] {
    return this.columns;
  }

  viewDetails(deletionBatchId: string): void {
    const to = 'reports/deleted-documents/' + deletionBatchId;
    this.router.navigate([to]);
  }

  exportDeletedDocsForDomainJob(purgeDomainJob: string): void {
    this.reportingService.exportDeletedDocsForDomainJob(purgeDomainJob)
      .subscribe({
        next: () => this.customSnackBarService.openSuccessSnackbar(this.translate.instant('snackBarMessage.exportStarted')),
        error: (error) => this.dialogService.error(error?.error?.error?.message ?? error.message)
      });
  }

  listPurgeDomainJob(): void {
    this.loadingSubject.next(true);
    this.reportingService.listDomainPurgeJobs().subscribe({
      next: (resp) => {
        this.dataSource.data = resp.items;
        this.loadingSubject.next(false);
      },
      error: (error) => {
        this.loadingSubject.next(false);
        this.dialogService.error(error?.error?.error?.message ?? error.message);
      }
    });
  }
}
