import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {RetentionService} from '../../../services/retention.service';
import {MatPaginator} from '@angular/material/paginator';
import {Policy} from '../../../models/retention.model';
import {MatTableDataSource} from '@angular/material/table';
import {BehaviorSubject, Subject} from 'rxjs';
import {DialogService} from '../../../services/dialog.service';
import {UserService} from '../../../services/user.service';
import {UserClassification} from '../../../constants/user-constants';
import {CsvImportStatisticsComponent} from '../../../components/csv-import-statistics/csv-import-statistics.component';
import {PoliciesViewerComponent} from '../viewer/policies-viewer.component';
import {TranslateService} from '@ngx-translate/core';
import {FinraPoliciesCreatorComponent} from '../creator/finra/finra-policies-creator.component';
import {StandardPoliciesCreatorComponent} from '../creator/standard/standard-policies-creator.component';
import {DEFAULT_RETENTIONS_ITEMS_PAGE_NUMBER, PolicyType} from '../../../constants/retention-constants';
import {FinraPoliciesEditorComponent} from '../editor/finra/finra-policies-editor.component';
import {StandardPoliciesEditorComponent} from '../editor/standard/standard-policies-editor.component';
import {AdminService} from '../../../services/admin.service';
import {DomainFeature} from '../../../constants/domain-feature-constants';

@Component({
  selector: 'app-policies-list',
  templateUrl: './policies-list.component.html',
  styleUrls: ['./policies-list.component.scss']
})
export class PoliciesListComponent implements OnInit, AfterViewInit, OnDestroy {

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public searchValueSubject: Subject<string> = new Subject<string>();
  public searchClearSubject: Subject<boolean> = new Subject();
  public loading$ = this.loadingSubject.asObservable();
  public dataSource: MatTableDataSource<Policy>;
  public policies: Policy[];
  public superAdmin: boolean = undefined;
  public finraSettings = false;
  public columns = [];
  public userDomainFeatures: DomainFeature[] = [];
  public retentionItemsLength: number = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private retentionService: RetentionService,
              private dialog: MatDialog,
              private userService: UserService,
              private adminService: AdminService,
              private dialogService: DialogService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    if (!this.userService.user) {
      this.userService.userSubject.subscribe(() => this.initUserData());
    } else {
      this.initUserData();
    }
    this.dataSource = new MatTableDataSource();
    this.loadPolicies();
    this.searchInit();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this.searchValueSubject.unsubscribe();
    this.userService.userSubject.unsubscribe();
  }

  create(policyType: PolicyType): void {
    const component = policyType === PolicyType.STANDARD ? StandardPoliciesCreatorComponent : FinraPoliciesCreatorComponent;
    this.dialog.open(component, {
      height: '615px',
      width: '680px'
    }).afterClosed().subscribe((choice) => {
      if (choice) {
        this.loadPolicies();
      }
    });
  }

  createStandardPolicy(): void {
    this.create(PolicyType.STANDARD);
  }

  createFinraPolicy(): void {
    this.create(PolicyType.FINRA);
  }

  refresh(): void {
    this.loadPolicies();
  }

  view(policy: Policy): void {
    this.dialog.open(PoliciesViewerComponent, {
      data: {policy, isSuperAdmin: this.superAdmin},
      width: '630px'
    }).afterClosed().subscribe((choice) => {
      if (choice) {
        this.edit(choice);
      }
    });
  }

  edit(policy: Policy): void {
    const component = policy.finra ? FinraPoliciesEditorComponent : StandardPoliciesEditorComponent;
    this.dialog.open(component, {
      data: policy,
      height: '600px',
      width: '680px'
    }).afterClosed().subscribe((choice) => {
      if (choice) {
        this.loadPolicies();
      }
    });
  }

  confirmDelete(policy: Policy): void {
    const deleteMessage = this.translate.instant('dialog.delete', {policy: policy.id});
    const dialogTitle = this.translate.instant('dialog.confirmation');
    this.dialogService.plain(deleteMessage, dialogTitle)
      .afterClosed().subscribe((choice) => {
      if (choice) {
        this.delete(policy.id);
      }
    });
  }

  onFileSelect(event, fileInput: HTMLInputElement): void {
    const file = event.target.files[0];
    const fileReader: FileReader = new FileReader();

    fileReader.readAsText(file);
    fileReader.onload = () => {
      const content = fileReader.result;
      fileInput.value = ''; // reset component in html
      this.retentionService.importCsv(content).subscribe({
        next: (resp) => {
          const dialogRef = this.dialog.open(CsvImportStatisticsComponent, {
            data: resp
          });
          dialogRef.afterClosed().subscribe(() => {
            this.loadPolicies();
          });
        },
        error: error => this.dialogService.error(error?.error?.error?.message ?? error.message)
      });
    };
  }

  public downloadCsv(): void {
    let data: string;
    let filename: string;
    let link: HTMLElement;

    let csv = this.retentionService.convertArrayOfObjectsToCSV({
      data: this.policies
    });
    if (csv == null) {
      return;
    }

    const timestamp = new Date().getTime();
    filename = timestamp + '_' + 'export.csv';

    if (!csv.match(/^data:text\/csv/i)) {
      csv = 'data:text/csv;charset=utf-8,' + csv;
    }
    data = encodeURI(csv);

    link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', filename);
    link.click();
  }

  searchInit(): void {
    this.searchValueSubject.subscribe((value) => {
      this.dataSource.filter = value.trim().toLowerCase();
    });
  }

  loadPolicies(): void {
    this.loadingSubject.next(true);
    this.retentionService.list().subscribe({
      next: (resp) => {
        this.policies = resp.items;
        this.dataSource.data = resp.items;
        this.retentionItemsLength =  this.dataSource.data.length;
        this.loadingSubject.next(false);
      },
      error: (error) => {
        this.loadingSubject.next(false);
        this.dialogService.error(error?.error?.error?.message ?? error.message);
      }
    });
  }

  delete(policyId: string): void {
    this.loadingSubject.next(true);
    this.retentionService.delete(policyId).subscribe({
      next: () => {
        this.loadingSubject.next(false);
        this.loadPolicies();
      },
      error: (error) => {
        this.loadingSubject.next(false);
        this.dialogService.error(error?.error?.error?.message ?? error.message);
      }
    });
  }

  getFinraSettings(): void {
    this.adminService.getFinraSettings().subscribe({
      next: (resp) => this.finraSettings = !!resp.temporaryBucket && !!resp.googleDriveLabel
      , error: (error) => this.dialogService.error(error?.error?.error?.message ?? error.message)
    });
  }

  initUserData(): void {
    this.isSuperAdmin();
    this.userDomainFeatures = this.userService.user.features;
    this.columns = [
      {name: 'id', show: true},
      {name: 'event', show: true},
      {name: 'offset', show: true},
      {name: 'retentionTime', show: true},
      {name: 'metadataBased', show: true},
      {name: 'function', show: true},
      {name: 'finra', show: this.userService.isFinra()},
      {name: 'isUsed', show: true},
      {name: 'view', show: true},
      {name: 'edit', show: this.superAdmin},
      {name: 'delete', show: this.superAdmin}];
    if (this.userService.isFinra()) {
      this.getFinraSettings();
    }
  }

  isSuperAdmin(): void {
    if (this.userService.user) {
      this.superAdmin = (this.userService.user.userClassification === UserClassification.AODOCS_SUPER_ADMIN)
        || (this.userService.user.userClassification === UserClassification.RETENTION_ADMIN);
    } else {
      this.superAdmin = undefined;
    }
  }

  isFinra(): boolean {
    return this.userService.isFinra();
  }

  displayedColumns(): string[] {
    return this.columns.filter(column => column.show).map(column => column.name);
  }

  public getPageSize(): number {
    return DEFAULT_RETENTIONS_ITEMS_PAGE_NUMBER;
  }

}
