import {Injectable} from '@angular/core';
import {ApiVersion} from '../constants/apis';
import {AbstractService} from './abstract.service';
import {UnlockedDocument} from '../models/unlocked-document.model';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentService extends AbstractService {

  constructor(private http: HttpClient) {
    super();
  }

  unlockDocument(documentId: string): Observable<void> {
    const url = this.toUrl('document', 'unlockDocument/' + documentId, ApiVersion.V1);
    return this.http.post<void>(url, null);
  }

  lockDocument(documentId: string): Observable<void> {
    const url = this.toUrl('document', 'lockDocument/' + documentId, ApiVersion.V1);
    return this.http.post<void>(url, null);
  }

  listUnlockedDocument(): Observable<{ items: UnlockedDocument[] }> {
    const url = this.toUrl('document', 'listUnlockedDocument', ApiVersion.V1);
    return this.http.get<{ items: UnlockedDocument[] }>(url);
  }

  getAodocsDocumentUrl(unlockedDocument: UnlockedDocument): string {
    return environment.aodocsUrl + '#Menu_viewDoc/LibraryId_' + unlockedDocument.libraryId + '/DocumentId_' + unlockedDocument.documentId;
  }

}
