<header>
  <h1 mat-dialog-title>{{'about.title' | translate}}</h1>
</header>
<div mat-dialog-content>
  <p>{{'about.version' | translate}}: {{version}}</p>
  <p>Copyright © 2013 Altirnao, Inc</p>
  <p>U.S. Patent 9,817,988</p>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button mat-dialog-close>OK</button>
</div>
