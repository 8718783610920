<section class="main-content">
  <h1>{{'title.uploadErrors' | translate}}</h1>
  <mat-card class="no-padding">
    <div class="spinner-container" *ngIf="loading$ | async">
      <mat-spinner></mat-spinner>
    </div>
    <section [hidden]="loading$ | async">
      <section fxLayout="row" fxLayoutAlign="space-between start">
        <section fxLayout="column" class="steps-container">
          <p class="grey-label label-margin">{{'holds.upload.warningMessage' | translate}}</p>
          <p class="grey-label label-margin"><span class="step1">{{'holds.upload.step1.title' | translate}}
            :&nbsp;</span>{{'holds.upload.step1.message' | translate}}</p>
          <p class="grey-label label-margin"><span class="step2">{{'holds.upload.step2.title' | translate}}
            :&nbsp;</span>{{'holds.upload.step2.message' | translate}}</p>
        </section>
        <section class="refresh-container">
          <button mat-icon-button (click)="list()" class="action-icon"
                  matTooltip="{{'policies.list.table.tooltips.refresh' | translate}}">
            <mat-icon>refresh</mat-icon>
          </button>
        </section>
      </section>
      <section>
        <table mat-table [dataSource]="dataSource" id="table" class="mat-elevation-z8">
          <!-- attachment -->
          <ng-container matColumnDef="attachment">
            <mat-header-cell *matHeaderCellDef>{{'holds.upload.table.attachment' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element"><span> {{element.attachmentName}}</span></mat-cell>
          </ng-container>

          <!-- document -->
          <ng-container matColumnDef="document">
            <mat-header-cell *matHeaderCellDef>{{'holds.upload.table.document' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span>{{element.documentTitle}}</span>
            </mat-cell>
          </ng-container>

          <!-- step1 -->
          <ng-container matColumnDef="step1">
            <mat-header-cell *matHeaderCellDef>{{'holds.upload.table.step1' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <section class="download-button" fxLayout="row" fxLayoutAlign="start center"
                       (click)="openDocumentPage(element)">
                <mat-icon>download</mat-icon>
                <span>download attachment</span>
              </section>
            </mat-cell>
          </ng-container>

          <!-- step2 -->
          <ng-container matColumnDef="step2">
            <mat-header-cell *matHeaderCellDef>{{'holds.upload.table.step2' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <section class="upload-button">
                <label for="{{'file-upload'+element.attachmentId}}" class="custom-file-upload" fxLayout="row"
                       fxLayoutAlign="start center">
                  <mat-icon>cloud_upload</mat-icon>
                  <span>upload to gcs</span>
                </label>
                <input id="{{'file-upload'+element.attachmentId}}" type="file" (change)="upload($event, element)">
              </section>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns()"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns();"></mat-row>
          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">{{"dataNotFound" | translate}}</td>
          </tr>
        </table>
        <section [hidden]="loading$ | async">
          <mat-paginator [length]="uploadErrorsCount" [pageSize]="getPageSize()" [showFirstLastButtons]="false" [pageSizeOptions]="[10,20,30]"></mat-paginator>
        </section>
      </section>
    </section>
  </mat-card>
  <section *ngIf="requiresNewScope">
    <ao-google-login
      [auto]="true"
      [clientId]="clientId"
      [scopes]="scopes"
      (loginSuccess)="googleLoginSuccess($event)"
      (loginError)="loginError()"
      display-mode="content"
      authentication-mode="token"
      id="gLogin">
    </ao-google-login>
  </section>

</section>
