import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {LibraryStatusList, LibraryTagFilter} from '../../constants/library-constants';
import {Subject} from 'rxjs';
import {MatSelect} from '@angular/material/select';

@Component({
  selector: 'app-library-tag-selector',
  templateUrl: './library-tag-selector.component.html',
  styleUrls: ['./library-tag-selector.component.scss']
})
export class LibraryTagSelectorComponent implements OnInit {
  public libraryStatusList = LibraryStatusList;
  public value: LibraryTagFilter = LibraryTagFilter.INACTIVE;
  @Input() valueSubject: Subject<LibraryTagFilter>;
  @Input() clearSubject: Subject<boolean>;
  @ViewChild(MatSelect) select: MatSelect;

  constructor() {
  }

  ngOnInit(): void {
    this.clearSubject.subscribe(v => {
      this.clear();
    });
  }

  changed(value: LibraryTagFilter): void {
    this.valueSubject.next(value);
  }

  clear(): void {
    this.select.value = LibraryTagFilter.INACTIVE;
    this.changed(LibraryTagFilter.INACTIVE);
  }
}
