import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {PoliciesEditorComponent} from '../policies-editor.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Policy} from '../../../../models/retention.model';
import {FormBuilder} from '@angular/forms';
import {RetentionService} from '../../../../services/retention.service';
import {DialogService} from '../../../../services/dialog.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-finra-policies-editor',
  templateUrl: './finra-policies-editor.component.html',
  styleUrls: ['./finra-policies-editor.component.scss', '../policies-editor.component.scss']
})
export class FinraPoliciesEditorComponent extends PoliciesEditorComponent implements OnInit {

  public constructor(@Inject(MAT_DIALOG_DATA) data: Policy,
                     dialogRef: MatDialogRef<PoliciesEditorComponent>,
                     formBuilder: FormBuilder,
                     cdr: ChangeDetectorRef,
                     retentionService: RetentionService,
                     dialogService: DialogService,
                     translate: TranslateService) {

    super(data, dialogRef, formBuilder, cdr, retentionService, dialogService, translate);
  }

  ngOnInit(): void {
    this.initIdentificationForm();
    this.initSetupForm();
    this.initOptionsForm();
  }

  timeChanged(): void {
    const setup = this.setupFormGroup.getRawValue();
    const updatedTime = setup.time;
    if (this.policy.retentionTime > updatedTime) {
      this.setupFormGroup.get('time').setValue(this.policy.retentionTime);
    }
  }
}
